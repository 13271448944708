var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Agregar movimiento manual")]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Descripción (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.movementRequiredErrors.DESCRIPCION },"message":{
        'La descripción del movimiento manual no es valido':
          _vm.movementRequiredErrors.DESCRIPCION,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Clave Pago de llanta de camioneta","expanded":"","required":"","icon":"clipboard"},model:{value:(_vm.newMovement.DESCRIPCION),callback:function ($$v) {_vm.$set(_vm.newMovement, "DESCRIPCION", $$v)},expression:"newMovement.DESCRIPCION"}})],1),_c('b-field',{attrs:{"label":"Cantidad (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.movementRequiredErrors.CANTIDAD },"message":{
        'La cantidad del movimiento manual no es valido':
          _vm.movementRequiredErrors.CANTIDAD,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 1000","required":true,"icon":"cash"},model:{value:(_vm.newMovement.CANTIDAD),callback:function ($$v) {_vm.$set(_vm.newMovement, "CANTIDAD", $$v)},expression:"newMovement.CANTIDAD"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":"","expanded":""}},[_c('b-field',{attrs:{"label-position":"inside","label":"Fecha (Requerido)","type":{ 'is-danger': _vm.movementRequiredErrors.FECHA },"message":{
        'La fecha no es valida': _vm.movementRequiredErrors.FECHA,
      },"expanded":""}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha","icon":"calendar-today","required":"","trap-focus":""},model:{value:(_vm.newMovement.FECHA),callback:function ($$v) {_vm.$set(_vm.newMovement, "FECHA", $$v)},expression:"newMovement.FECHA"}})],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addManualMovement()}}},[_vm._v("Añadir movimiento manual")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }