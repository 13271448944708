<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="general-balance-all-graph-container">
      <div
        class="information-cards-container"
        v-if="providersAccounts.length > 0 || clientsAccounts.length > 0"
      >
        <InformationCard
          name="Saldo total en todas las cuentas bancarias"
          :value="totalBalance"
          :max="totalFinalBalance"
          format="money"
          width="half"
        />

        <InformationCard
          name="Saldo consolidado total en todas las cuentas bancarias"
          :value="totalBalanceCon"
          :max="totalFinalBalance"
          format="money"
          width="half"
        />
      </div>

      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-left special-margin"
        v-if="providersAccounts.length > 0"
      >
        <apexchart
          width="90%"
          type="donut"
          :options="accountsChart.options"
          :series="accountsChart.series"
        ></apexchart>
      </div>

      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-right special-margin"
        v-if="clientsAccounts.length > 0"
      >
        <apexchart
          width="90%"
          type="donut"
          :options="accountsChartSale.options"
          :series="accountsChartSale.series"
        ></apexchart>
      </div>

      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-left"
        v-if="providersAccounts.length > 0"
      >
        <apexchart
          width="90%"
          type="donut"
          :options="accountsChartPaid.options"
          :series="accountsChartPaid.series"
        ></apexchart>
      </div>

      <div
        class="general-balance-graph-container global-border-radius global-border-shadow general-balance-graph-container-left"
        v-if="providersAccounts.length > 0"
      >
        <apexchart
          width="90%"
          type="donut"
          :options="accountsChartSalePaid.options"
          :series="accountsChartSalePaid.series"
        ></apexchart>
      </div>

      <div
        class="message-full-width global-border-radius global-border-shadow"
        v-if="providersAccounts.length === 0 && clientsAccounts.length === 0"
      >
        <p>No hay cuentas en el sistema</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InformationCard from "../../Global/components/InformationCard";
// @ is an alias to /src
export default {
  name: "GeneralResumee",
  components: {
    InformationCard,
  },
  data() {
    return {
      accountsChart: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Por pagar en cuentas por pagar",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",

                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [0, 0],
      },
      accountsChartSale: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Por cobrar en cuentas por cobrar",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",

                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [0, 0],
      },
      accountsChartPaid: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Pagado en cuentas por pagar",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",

                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [0, 0],
      },
      accountsChartSalePaid: {
        options: {
          legend: {
            fontSize: "15px",
            position: "bottom",
          },
          chart: {
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
              },
            },
          },
          title: {
            text: "Pagado en cuentas por cobrar",
            align: "left",
            offsetY: 15,
            style: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          },
          labels: [],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    color: "#373d3f",
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    color: "#373d3f",
                    offsetY: 16,
                    formatter: function (val) {
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(val));
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",

                    color: "#373d3f",
                    formatter: function (w) {
                      let currentTtotal = w.globals.seriesTotals.reduce(
                        (a, b) => {
                          return a + b;
                        },
                        0
                      );
                      return new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(currentTtotal));
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 960,
              options: {
                chart: {
                  width: "100%",
                  height: "800px",
                },
              },
            },
          ],
        },
        series: [0, 0],
      },
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(
        this.$store.dispatch("GETINCOMES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );

      await Promise.all(allPromises);

      this.updateSeries();
      this.updateSeriesSales();
      this.updateSeriesPaid();
      this.updateSeriesSalesPaid();

      this.refreshLoading = false;
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETEXPENSES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        allPromises.push(
          this.$store.dispatch("GETINCOMES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);

        this.updateSeries();
        this.updateSeriesSales();
        this.updateSeriesPaid();
        this.updateSeriesSalesPaid();
      }
    },
    updateSeries() {
      this.refreshLoading = true;

      let totalProviders = 0;
      let totalOther = 0;

      for (let i = 0; i < this.providersAccounts.length; i++) {
        if (this.providersAccounts[i].TIPO_EGR === "C") {
          totalProviders += this.providersAccounts[i].RESTA
            ? Number(this.providersAccounts[i].RESTA)
            : 0;
        } else {
          totalOther += this.providersAccounts[i].RESTA
            ? Number(this.providersAccounts[i].RESTA)
            : 0;
        }
      }
      this.accountsChart.options = {
        ...this.accountsChart.options,
        labels: [
          "Cantidad en cuentas de proveedores",
          "Cantidad en otras cuentas",
        ],
      };
      this.accountsChart.series = [Number(totalProviders), Number(totalOther)];

      this.refreshLoading = false;
    },
    updateSeriesSales() {
      this.refreshLoading = true;

      let totalClients = 0;
      let totalOther = 0;

      for (let i = 0; i < this.clientsAccounts.length; i++) {
        if (this.clientsAccounts[i].TIPO_ING === "V") {
          totalClients += this.clientsAccounts[i].RESTA
            ? Number(this.clientsAccounts[i].RESTA)
            : 0;
        } else {
          totalOther += this.clientsAccounts[i].RESTA
            ? Number(this.clientsAccounts[i].RESTA)
            : 0;
        }
      }
      this.accountsChartSale.options = {
        ...this.accountsChartSale.options,
        labels: [
          "Cantidad en cuentas de clientes",
          "Cantidad en otras cuentas",
        ],
      };
      this.accountsChartSale.series = [
        Number(totalClients),
        Number(totalOther),
      ];

      this.refreshLoading = false;
    },
    updateSeriesPaid() {
      this.refreshLoading = true;

      let totalProviders = 0;
      let totalOther = 0;

      for (let i = 0; i < this.providersAccounts.length; i++) {
        if (this.providersAccounts[i].TIPO_EGR === "C") {
          totalProviders +=
            Number(this.providersAccounts[i].TOTAL_GTO) -
            Number(this.providersAccounts[i].RESTA);
        } else {
          totalOther +=
            Number(this.providersAccounts[i].TOTAL_GTO) -
            Number(this.providersAccounts[i].RESTA);
        }
      }

      this.accountsChartPaid.options = {
        ...this.accountsChartPaid.options,
        labels: [
          "Cantidad en cuentas de proveedores",
          "Cantidad en otras cuentas",
        ],
      };
      this.accountsChartPaid.series = [
        Number(totalProviders),
        Number(totalOther),
      ];

      this.refreshLoading = false;
    },
    updateSeriesSalesPaid() {
      this.refreshLoading = true;

      let totalClients = 0;
      let totalOther = 0;

      for (let i = 0; i < this.clientsAccounts.length; i++) {
        if (this.clientsAccounts[i].TIPO_ING === "V") {
          totalClients +=
            Number(this.clientsAccounts[i].TOTAL_GTO) -
            Number(this.clientsAccounts[i].RESTA);
        } else {
          totalOther +=
            Number(this.clientsAccounts[i].TOTAL_GTO) -
            Number(this.clientsAccounts[i].RESTA);
        }
      }
      this.accountsChartSalePaid.options = {
        ...this.accountsChartSalePaid.options,
        labels: [
          "Cantidad en cuentas de clientes",
          "Cantidad en otras cuentas",
        ],
      };
      this.accountsChartSalePaid.series = [
        Number(totalClients),
        Number(totalOther),
      ];

      this.refreshLoading = false;
    },
  },
  created() {
    moment.locale("es");
  },
  mounted() {
    this.updateSeries();
    this.updateSeriesSales();
    this.updateSeriesPaid();
    this.updateSeriesSalesPaid();
  },
  computed: {
    providersAccounts() {
      return this.$store.getters.EXPENSES.map((singleExpense) => {
        let singleExpenseWithProps = { ...singleExpense };
        return singleExpenseWithProps;
      }).filter((singleExpense) => {
        return singleExpense.STATUS !== "CA";
      });
    },
    clientsAccounts() {
      return this.$store.getters.INCOMES.map((singleIncome) => {
        let singleIncomeWithProps = { ...singleIncome };
        return singleIncomeWithProps;
      }).filter((singleIncome) => {
        return singleIncome.STATUS !== "CA";
      });
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
    totalFinalBalance() {
      return this.totalBalance;
    },
  },
  watch: {
    providersAccounts() {
      this.updateSeries();
      this.updateSeriesSales();
    },
    clientsAccounts() {
      this.updateSeriesSales();
      this.updateSeriesSalesPaid();
    },
  },
};
</script>

<style scoped>
@import "../../Bank/style/GeneralResumeeBank.css";
@import "../../Global/style/Global.css";
</style>
