var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Editar información de cuenta por cobrar ")]),_vm._m(0),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":"","type":{ 'is-danger': _vm.incomeInformationRequiredErrors.NO_DOCUM },"message":{
      'El número de documento no es válido':
        _vm.incomeInformationRequiredErrors.NO_DOCUM,
    }}},[_c('b-field',{attrs:{"label":"Número de documento (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Venta número 50 del cliente 2","expanded":"","icon":"numeric-9-circle-outline","required":""},model:{value:(_vm.incomeInformation.NO_DOCUM),callback:function ($$v) {_vm.$set(_vm.incomeInformation, "NO_DOCUM", $$v)},expression:"incomeInformation.NO_DOCUM"}})],1),_c('b-field',{attrs:{"label":"Descripción (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Venta número 50 del cliente 2","expanded":"","icon":"clipboard"},model:{value:(_vm.incomeInformation.OBSERVA),callback:function ($$v) {_vm.$set(_vm.incomeInformation, "OBSERVA", $$v)},expression:"incomeInformation.OBSERVA"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":"","expanded":""}},[_c('b-field',{attrs:{"label-position":"inside","label":"Fecha (Requerido)","type":{ 'is-danger': _vm.incomeInformationRequiredErrors.FECHA },"message":{
        'La fecha no es valida': _vm.incomeInformationRequiredErrors.FECHA,
      },"expanded":""}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha","icon":"calendar-today","required":"","trap-focus":""},model:{value:(_vm.incomeInformation.FECHA),callback:function ($$v) {_vm.$set(_vm.incomeInformation, "FECHA", $$v)},expression:"incomeInformation.FECHA"}})],1),_c('b-field',{attrs:{"label-position":"inside","label":"Fecha de documento (Requerido)","type":{ 'is-danger': _vm.incomeInformationRequiredErrors.FECHA_DOC },"message":{
        'La fecha de documento no es valida':
          _vm.incomeInformationRequiredErrors.FECHA_DOC,
      },"expanded":""}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha","icon":"calendar-today","required":"","trap-focus":""},model:{value:(_vm.incomeInformation.FECHA_DOC),callback:function ($$v) {_vm.$set(_vm.incomeInformation, "FECHA_DOC", $$v)},expression:"incomeInformation.FECHA_DOC"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":"","expanded":""}},[_c('b-field',{attrs:{"label-position":"inside","label":"Fecha de vencimiento (Requerido)","type":{ 'is-danger': _vm.incomeInformationRequiredErrors.FECHA_VEN },"message":{
        'La fecha de vencimineto no es valida':
          _vm.incomeInformationRequiredErrors.FECHA_VEN,
      },"expanded":""}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha","icon":"calendar-today","required":"","trap-focus":""},model:{value:(_vm.incomeInformation.FECHA_VEN),callback:function ($$v) {_vm.$set(_vm.incomeInformation, "FECHA_VEN", $$v)},expression:"incomeInformation.FECHA_VEN"}})],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.editAccount()}}},[_vm._v("Editar cuenta por cobrar")]),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado ")])])}]

export { render, staticRenderFns }