var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Cancelación de pago")]),_vm._m(0),_c('div',{attrs:{"id":"belvo"}}),_c('div',{staticClass:"manual-bank-account"},[_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin required-input",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Motivo de cancelación (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newCancellationRequiredErrors.RAZON_CAN },"message":{
          'El motivo no es válido': _vm.newCancellationRequiredErrors.RAZON_CAN,
        }}},[_c('b-select',{attrs:{"placeholder":"Motivo de cancelación","required":"","expanded":""},model:{value:(_vm.newCancellation.RAZON_CAN),callback:function ($$v) {_vm.$set(_vm.newCancellation, "RAZON_CAN", $$v)},expression:"newCancellation.RAZON_CAN"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" Selecciona un motivo de cancelación ")]),_vm._l((_vm.cfdiCancellationReasons),function(cancellationReason){return _c('option',{key:cancellationReason.CLAVE,domProps:{"value":cancellationReason.CLAVE}},[_vm._v(" "+_vm._s(cancellationReason.DESCRIPCION)+" ")])})],2)],1)],1)],1),_c('div',{staticClass:"add-global-controls"},[_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){_vm.startConfirmationEvent(
          'success',
          function () {
            _vm.cancelCFDI();
          },
          'cancelar pago'
        )}}},[_vm._v("Cancelar pago")]),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"global-form-hint-container"},[_c('p',{staticClass:"global-form-hint"},[_vm._v("Los campos requeridos tienen el marco")]),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Morado")]),_vm._v(". Al hacer click en \"Cancelar pago\" se realiza la solicitud de cancelación para ver el estatus de cancelación es necesario conectar su cuenta del SAT en la sección de \"Documentos SAT\" ")])}]

export { render, staticRenderFns }