/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar pago usando anticipo</h1>

    <b-checkbox
      v-model="newPayment.requireCDFI"
      v-if="!objectInformation && accountType === 'CC' && type === 'Venta'"
      class="special-margin"
    >
      Timbrar pago
    </b-checkbox>

    <b-field grouped class="special-margin">
      <b-field
        label="Restante a pagar (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="totalQuantity"
          :required="true"
          :disabled="true"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Información del anticipo</h3>
    <b-field grouped class="special-margin required-input">
      <b-field
        label="Anticipo (Requerido)"
        expanded
        label-position="inside"
        required
        class="required-input"
      >
        <b-select
          placeholder="Ejemplo: 100"
          expanded
          icon="credit-card-outline"
          v-model="newPayment.CLAVE_ANTICIPO"
          :type="{ 'is-danger': newPaymentErrors.CLAVE_ANTICIPO }"
          :message="{
            'El anticipo no es valido': newPaymentErrors.CLAVE_ANTICIPO,
          }"
          @change.native="setAdvancedPaymentBalance()"
        >
          <option value="" disabled>Sin seleccionar</option>
          <option
            :value="singleAdvancesPayment._id"
            v-for="singleAdvancesPayment in advancedPayment"
            v-bind:key="singleAdvancesPayment._id"
          >
            {{ singleAdvancesPayment.NUM_FOLIO }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin required-input">
      <b-field
        label="Saldo restante en anticipo (Requerido)"
        expanded
        label-position="inside"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="selectedAdvancedPaymentBalance"
          :required="true"
          :disabled="true"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Monto (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.PAGO_PARC }"
        :message="{
          'El monto del pago no es valido': newPaymentErrors.PAGO_PARC,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 16"
          icon="cash-100"
          v-model="newPayment.PAGO_PARC"
          :required="true"
          :disabled="false"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Fecha de pago (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.FECHA_PAGO }"
        :message="{
          'La fecha del pago no es valida': newPaymentErrors.FECHA_PAGO,
        }"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de pago"
          icon="calendar-today"
          required
          v-model="newPayment.FECHA_PAGO"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        label="Referencia (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newPaymentErrors.REFERENCIA }"
        :message="{
          'La referencia del pago no es valida': newPaymentErrors.REFERENCIA,
        }"
      >
        <b-input
          placeholder="Ejemplo: 12387BH"
          expanded
          icon="pencil-box"
          required
          v-model="newPayment.REFERENCIA"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin">
      <b-field
        label="Observaciones (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Pago realizado el día Martes"
          expanded
          v-model="newPayment.OBSERVACIONES"
          type="textarea"
        ></b-input>
      </b-field>
    </b-field>

    <b-button type="is-success" @click="addNewPayment()"
      >Registrar pago</b-button
    >

    <b-button
      type="is-success"
      v-if="
        objectInformation &&
        objectInformation.STATUS_FAC === 'SF' &&
        type === 'Venta'
      "
      @click="savePaymentInvoice()"
      >Timbrar pago</b-button
    >

    <b-button
      type="is-danger"
      @click="closeModal()"
      :class="{
        'cancel-button':
          !objectInformation ||
          (objectInformation &&
            objectInformation.STATUS_FAC === 'SF' &&
            type === 'Venta'),
      }"
      >Regresar</b-button
    >
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
export default {
  name: "UseAdvancedPayment",
  props: ["expense", "income", "type", "accountType", "objectInformation"],
  components: {},
  data() {
    return {
      // Table values
      newPayment: {
        CLAVE_FACTURA: [],
        CLAVE_ANTICIPO: "",
        CUENTA: "",
        CLAVE_PRV: undefined,
        CLAVE_CLIENTE: undefined,
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        FAC_PRO: "",
        NO_DOCUM: "",
        PAGO_PARC: "",
        FECHA_PAGO: new Date(),
        TIPO_PAGOP: "A",
        CLAVE_NOT: "",
        REFERENCIA: "",
        FORMA_P: "",
        TIPO_CH: "",
        STATUS: "AC",
        OBSERVACIONES: "",
        createMovement: false,
        affectBalance: false,
        requireCDFI: false,
      },
      newPaymentErrors: {
        CLAVE_ANTICIPO: false,
        PAGO_PARC: false,
        FECHA_PAGO: false,
        REFERENCIA: false,
      },
      selectedAdvancedPaymentBalance: 0,
      moment: moment,
      totalQuantity: 0,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    if (!this.objectInformation) {
      if (this.accountType === "CP") {
        this.newPayment.CLAVE_OP = this.expense.NUM_FOLIO;
        this.newPayment.CLAVE_PRV = this.expense.CLAVE_PRV._id;
        this.newPayment.CLAVE_ALM = this.expense.CLAVE_ALMACEN._id;
        this.newPayment.FAC_PRO = this.expense.FACTURA;
        this.newPayment.GASTO_ID = this.expense._id;
        this.totalQuantity = this.expense.RESTA;
      } else {
        this.newPayment.CLAVE_FACTURA = [this.income.FACTURA_ID];
        this.newPayment.CLAVE_OP = this.income.NUM_FOLIO;
        this.newPayment.CLAVE_CLIENTE = this.income.CLAVE_CLIENTE._id;
        this.newPayment.CLAVE_ALM = this.income.CLAVE_ALMACEN._id;
        this.newPayment.NO_DOCUM = this.income.NO_DOCUM;
        this.newPayment.INGRESO_ID = this.income._id;
        this.totalQuantity = this.income.RESTA;
      }
      this.newPayment.TIPO_ING = this.type;
      this.newPayment.ES_ANTICIPO = "S";
    }

    if (this.objectInformation) {
      // Fill the form with received information
      fillObjectForm(this.objectInformation, this.newPayment);
      this.newPayment.FORMA_P = this.objectInformation.FORMA_P._id;
      this.newPayment.FECHA_PAGO = new Date(this.objectInformation.FECHA_PAGO);
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async addNewPayment() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newPayment,
          this.newPaymentErrors
        );
        if (checkValidationErrors(this.newPaymentErrors)) {
          if (this.accountType === "CP" && this.expenseNotValidQuantity()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el restante a pagar, el restante es: $${this.expense.RESTA}`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          if (this.expenseNotValidQuantityAdvancedPayment()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el saldo restante en el anticipo`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          let response = await this.$store.dispatch(
            this.accountType === "CP"
              ? "USEADVANCEDPAYMENT"
              : "USEADVANCEDPAYMENTINCOME",
            this.newPayment
          );
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el pago",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el pago en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el pago en la base de datos revise los campos Requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el pago la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async savePaymentInvoice() {
      try {
        let response = await this.$store.dispatch("SAVEINVOICEPAYMENT", {
          saleId: this.income.CLAVE_VENTA._id,
          paymentId: this.objectInformation._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: `Se generó el timbrado exitosamente`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al timbrar:  ${
              response ? JSON.stringify(response) : "Error en el servidor"
            }`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al timbrar en la base de datos: ${
            error ? JSON.stringify(error) : "Error en el servidor"
          }`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    expenseNotValidQuantity() {
      return this.expense.RESTA < this.newPayment.PAGO_PARC;
    },
    expenseNotValidQuantityAdvancedPayment() {
      return this.selectedAdvancedPaymentBalance < this.newPayment.PAGO_PARC;
    },
    /**
     *
     * @desc sets the balance of the current advanced payment
     * @return { number } the balance of the current advanced payment
     */
    setAdvancedPaymentBalance() {
      for (const singleAdvancedPayment of this.advancedPayment) {
        if (this.newPayment.CLAVE_ANTICIPO === singleAdvancedPayment._id) {
          this.selectedAdvancedPaymentBalance = singleAdvancedPayment.RESTA;
        }
      }
      return 0;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS;
    },
    lastPaymentNumber() {
      return this.$store.getters.LASTPAYMENTNUMBER;
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    advancedPayment() {
      return this.$store.getters.ADVANCEDPAYMENTS.map((singlePayment) => {
        const paymentWithProps = { ...singlePayment };
        return paymentWithProps;
      }).filter((singlePayment) => {
        if (this.accountType === "CC") {
          return (
            singlePayment.CLAVE_CLIENTE &&
            singlePayment.CLAVE_CLIENTE._id === this.newPayment.CLAVE_CLIENTE &&
            singlePayment.STATUS !== "CA"
          );
        } else {
          return (
            singlePayment.CLAVE_PRV &&
            singlePayment.CLAVE_PRV._id === this.newPayment.CLAVE_PRV &&
            singlePayment.STATUS !== "CA"
          );
        }
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
