var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}},[(_vm.typeValue === 0)?_c('div',{staticClass:"control"},[(_vm.providers.length === 0 || _vm.warehouses.length === 0)?_c('b-tooltip',{attrs:{"label":"Primero debes agregar proveedores y almacenes al sistema o esperar a que el sistema cargue estos datos","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Agregar cuenta por pagar")])],1):_vm._e(),(_vm.providers.length > 0 && _vm.warehouses.length)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.addExpenseAccount()}}},[_vm._v("Agregar cuenta por pagar")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"control"},[(_vm.clients.length > 0)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openAddPaymentMultipleAccount()}}},[_vm._v("Agregar mismo pago a multiples cuentas por pagar")]):_vm._e()],1),(_vm.typeValue === 0)?_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startImport()}}},[_vm._v("Importar gastos")])],1):_vm._e(),_c('div',{staticClass:"control"},[(
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.treasury.removeMissingAccountsExpenses ===
            'S' &&
          _vm.permissions &&
          _vm.permissions.treasury.removeMissingAccountsExpenses === 'S'
        )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.removeThresholdMissingAccounts()}}},[_vm._v("Eliminar saldos menores")]):_vm._e()],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.refreshLoading},on:{"click":function($event){return _vm.reloadInformation()}}},[_vm._v("Refrescar información")])],1)]),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{attrs:{"label":"Filtrar por proveedor","label-position":"inside"}},[_c('b-select',{staticClass:"global-lists-filters-control",attrs:{"placeholder":"Filtrar por proveedor","icon":"account-group-outline"},model:{value:(_vm.filterProvider),callback:function ($$v) {_vm.filterProvider=$$v},expression:"filterProvider"}},[_c('option',{attrs:{"value":""}},[_vm._v("Todos los provedores")]),_vm._l((_vm.providers),function(provider){return _c('option',{key:provider.NOMBRE_PRV,domProps:{"value":provider}},[_vm._v(" "+_vm._s(provider.NOMBRE_PRV)+" ")])})],2)],1)],1),_c('div',{staticClass:"global-lists-filters-container"},[_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de inicio","label-position":"inside","type":{ 'is-danger': _vm.errors.startDate },"message":{
          'La fecha de inicio no es valida': _vm.errors.startDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de inicio","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-field',{staticClass:"no-margin",attrs:{"label":"Fecha de fin","label-position":"inside","type":{ 'is-danger': _vm.errors.endDate },"message":{
          'La fecha de fin no es valida': _vm.errors.endDate,
        }}},[_c('b-datepicker',{staticClass:"global-lists-filters-control",attrs:{"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre' ],"show-week-number":true,"locale":"es-MX","placeholder":"Fecha de fin","icon":"calendar-today","trap-focus":""},on:{"input":function (value) { return _vm.changeDateFilterValue(value); }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de cuentas por pagar: "),_c('b',[_vm._v(_vm._s(_vm.filteredAccounts.length))])]),_c('div',{staticClass:"global-list-main-container"},[_c('b-table',{ref:"Brands",attrs:{"pagination-position":"both","data":_vm.filteredAccounts,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedBrands,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"desc","checkbox-position":"left","scrollable":"","default-sort":"NUM_FOLIO"},on:{"update:checkedRows":function($event){_vm.checkedBrands=$event},"update:checked-rows":function($event){_vm.checkedBrands=$event},"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.NUM_FOLIO)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay cuentas por pagars")])]},proxy:true},{key:"default",fn:function(props){return _vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label,"custom-sort":column.customSort,"cell-class":props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
              ? 'red-cell'
              : ''}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]) && !column.money)?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(column.money)?_c('span',[_vm._v(_vm._s(_vm._f("money")(props.row[column.field],"MXN", 2)))]):_vm._e(),(!column.money)?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"controls"},[(
                  props.row.STATUS === 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury.authorizeExpense ===
                    'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.authorizeExpense === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.authorizeExpense(props.row)}}},[_vm._v("Autorizar cuenta por pagar")]):_vm._e(),(
                  props.row.RESTA <= 0 &&
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA'
                )?_c('b-tooltip',{attrs:{"label":"La cuenta por pagar ha sido pagada en su totalidad","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Agregar nuevo pago")])],1):_vm._e(),(
                  props.row.RESTA > 0 &&
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.addPayment(props.row)}}},[_vm._v("Agregar nuevo pago ")]):_vm._e(),(
                  props.row.RESTA > 0 &&
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury.useAdvancedPayment ===
                    'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.useAdvancedPayment === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.useAdvancedPayment(props.row)}}},[_vm._v("Agregar nuevo pago usando anticipo ")]):_vm._e(),(props.row.STATUS !== 'NA')?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.showExpenseDetails(props.row)}}},[_vm._v("Ver detalles y pagos ")]):_vm._e(),(
                  _vm.type === 0 &&
                  props.row.STATUS !== 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury.cancelExpense === 'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.cancelExpense === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){_vm.startCancelEvent(
                    'danger',
                    function () {
                      _vm.cancelExpense(props.row);
                    },
                    'cancelar la cuenta por pagar'
                  )}}},[_vm._v("Cancelar cuenta por cobrar ")]):_vm._e(),(
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury
                    .addManualMovementExpense === 'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.addManualMovementExpense === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.addManualMovement(props.row)}}},[_vm._v("Agregar movimientos manuales")]):_vm._e(),(
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury
                    .addManualMovementExpense === 'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.addManualMovementExpense === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.manualMovementList(props.row)}}},[_vm._v("Ver movimientos manuales")]):_vm._e(),(
                  props.row.STATUS !== 'CA' &&
                  props.row.STATUS !== 'NA' &&
                  _vm.companyPaymentPlanModules &&
                  _vm.companyPaymentPlanModules.treasury
                    .editExpenseBasicInformation === 'S' &&
                  _vm.permissions &&
                  _vm.permissions.treasury.editExpenseBasicInformation === 'S'
                )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.editProviderAccount(props.row)}}},[_vm._v("Editar cuenta por pagar")]):_vm._e()],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }