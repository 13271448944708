/* eslint-disable */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar cuenta por cobrar</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado</span>. Para borrar un
      ingreso de la table solo es necesario dejar el concepto en blanco
    </div>

    <h3 class="form-division">Información general</h3>
    <div class="invoice-add-item special-margin">
      <b-field
        class="invoice-add-secondary-item-three required-input"
        label-position="inside"
        label="Cliente (Req)"
        required
        :type="{ 'is-danger': newIncomeErrors.CLAVE_CLIENTE }"
      >
        <b-select
          placeholder="Cliente"
          icon="earth"
          v-model="newIncome.CLAVE_CLIENTE"
          expanded
        >
          <option value="" disabled>Seleccione un cliente</option>
          <option
            v-for="client in clients"
            :value="client._id"
            :key="client.NOMBRE_EMPRESA"
          >
            {{ client.NOMBRE_EMPRESA }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-three"
        label-position="inside"
        label="Fecha de ingreso (Req)"
        :type="{ 'is-danger': newIncomeErrors.FECHA }"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de ingreso"
          icon="calendar-today"
          required
          v-model="newIncome.FECHA"
          trap-focus
        >
        </b-datepicker>
      </b-field>

      <b-field
        class="invoice-add-secondary-item-three required-input"
        label-position="inside"
        label="Almacenes (Req)"
      >
        <b-taginput
          :data="warehouses"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="NOMBRE_ALM"
          icon="warehouse"
          placeholder="Nombre de almacenes"
          check-infinite-scroll
          @add="(value) => addWarehouse(value)"
          @remove="(value) => removeWarehouse(value)"
          v-model="selectedWarehouses"
        >
          <template #empty>
            <div class="has-text-centered">No hay almacenes</div>
          </template>
        </b-taginput>
      </b-field>
    </div>

    <div>
      <h3 class="form-division">Detalles del ingreso</h3>

      <div class="invoice-add-item">
        <b-field
          class="invoice-add-secondary-item-one special-margin"
          label-position="inside"
          label="Número de documento (Req)"
          :type="{ 'is-danger': newIncomeErrors.NO_DOCUM }"
        >
          <b-input
            placeholder="Número de documento"
            icon="numeric-9-circle-outline"
            required
            type="text"
            v-model="newIncome.NO_DOCUM"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Fecha de documento (Req)"
          :type="{ 'is-danger': newIncomeErrors.FECHA_DOC }"
        >
          <b-datepicker
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha de documento"
            icon="calendar-today"
            required
            v-model="newIncome.FECHA_DOC"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Comentarios (Opc)"
        >
          <b-input
            placeholder="Comentarios"
            type="text"
            icon="magnify-plus-outline"
            v-model="newIncome.OBSERVA"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Plazo en días (Req)"
          :type="{ 'is-danger': newIncomeErrors.PERI }"
        >
          <b-input
            placeholder="Plazo"
            icon="numeric-4-circle-outline"
            required
            type="text"
            v-model="newIncome.PERI"
          >
          </b-input>
        </b-field>
      </div>

      <h3 class="form-division">Ingresos</h3>
      <b-table
        pagination-position="both"
        :data="newIncome.items"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="purchaseOrder"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table providers-list-global"
        scrollable
        default-sort="CONCEP_IE"
        :opened-detailed="[1]"
        detailed
        detail-key="RENGLON"
      >
        <template #empty>
          <div class="has-text-centered">No hay ingresos</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Concepto" field="CONCEP_IE" sortable>
            <b-input
              placeholder="Ejemplo: Pago de Luz"
              expanded
              v-model="props.row.CONCEP_IE"
              required
              @focus="addRow(props.row.RENGLON)"
            ></b-input>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIBEAR" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.DESCRIBEAR"
            ></b-input>
          </b-table-column>

          <b-table-column label="Costo" field="PRECIO_U" sortable>
            <b-input
              placeholder="Ejemplo: 100"
              type="number"
              step="any"
              expanded
              v-model="props.row.PRECIO_U"
              required
              @input="updateTotal()"
            ></b-input>
          </b-table-column>

          <b-table-column label="Porcentaje de IVA" field="PORCEN_IVA" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORCEN_IVA"
              @input="updateTotal()"
            />
          </b-table-column>

          <b-table-column
            label="Porcentaje de IEPS"
            field="PORCEN_IEPS"
            sortable
          >
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.PORCEN_IEPS"
              @input="updateTotal()"
            />
          </b-table-column>

          <b-table-column label="Total de IVA" field="IVA_GTO" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.IVA_GTO"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Total de IEPS" field="IEPS_GTO" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.IEPS_GTO"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Total" field="TOTAL" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              type="number"
              step="any"
              expanded
              v-model="props.row.TOTAL"
              required
              disabled
            ></b-input>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <div class="title-expandable-item">
                  <!-- eslint-disable vue/valid-v-for -->
                  <div
                    v-for="singlePercentageDivision in props.row
                      .percentageDivision"
                    v-bind:key="getRandomString()"
                  >
                    <div class="title-expandable-item">
                      <strong
                        >Porcentaje en almacen
                        <span class="global-form-hint-color">
                          {{ singlePercentageDivision.ALMACEN.CLAVE_ALMACEN }}
                        </span></strong
                      >
                    </div>

                    <div class="special-margin">
                      <b-field
                        :label="`Porcentaje de cuenta por cobrar en almacen ${singlePercentageDivision.ALMACEN.CLAVE_ALMACEN}`"
                        expanded
                        class="purchsase-order-detail-input"
                        label-position="inside"
                      >
                        <PercentageInput
                          placeholder="Ejemplo: 4"
                          expanded
                          type="number"
                          step="any"
                          icon="warehouse"
                          v-model="singlePercentageDivision.PORCENTAGE"
                        />
                      </b-field>
                    </div>
                  </div>
                  <!-- eslint-enable -->
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Subtotal:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newIncome.IMPORTE_E | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IVA:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newIncome.IVA_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info-discounts">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>IEPS:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newIncome.IEPS_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="invoice-payment-total-info">
      <div class="invoice-payment-total-left"></div>
      <div class="invoice-payment-total-rigth">
        <strong>Total:</strong>
        <strong class="invoice-payment-total-money">{{
          this.newIncome.TOTAL_GTO | money("MXN", 2)
        }}</strong>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveIncome('T')"
        >Registrar cuenta por cobrar</b-button
      >

      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import {
  checkValidationErrors,
  validateFields,
  createRandomString,
  round,
  toFixedNoRound,
  basicRuleOfThree,
} from "../../../utils/fns";
export default {
  name: "AddNewIncome",
  components: {},
  data() {
    return {
      // Table values
      currentIndex: 1,
      newIncome: {
        CLAVE_CLIENTE: "",
        CLAVE_ALM: "",
        FECHA: new Date(),
        NO_DOCUM: "",
        FECHA_DOC: new Date(),
        PERI: 0,
        IMPORTE_E: 0,
        IVA_GTO: 0,
        IEPS_GTO: 0,
        IMPIMPTO1: 0,
        TOTAL_GTO: 0,
        RESTA: 0,
        OBSERVA: "",
        DESCRIPCION: "Agregado de cuenta por cobrar manual",
        EXENTO: 0,
        TIPO_ING: "I",
        items: [
          {
            CONCEP_IE: "",
            RENGLON: 1,
            PORCEN_IVA: 16,
            PORCEN_IEPS: 0,
            PRECIO_U: 0,
            IVA_GTO: 0,
            IEPS_GTO: 0,
            COST_GTO: 0,
            DESC_GTO: 0,
            ES_ACTIVOF: "N",
            DESCRIBEAR: "",
            SUB_TOT: 0,
            TOTAL: 0,
            CANTIDAD: 1,
            EXENTO: 0,
            ES_EXENTO: "N",
            percentageDivision: [],
          },
        ],
      },
      newIncomeErrors: {
        CLAVE_CLIENTE: false,
        FECHA: false,
        FECHA_DOC: false,
        PERI: false,
        NO_DOCUM: false,
      },
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      selectedWarehouses: [],
      incomeDetails: [],
    };
  },
  async created() {
    moment.locale("es");
    this.startingDateFormated = moment(new Date()).format("DD-MM-YYYY HH:mm");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     *
     * @desc add a new row when focus concepto fields in rows, only add row first time focus
     */
    addRow(renglon) {
      if (renglon === this.currentIndex) {
        // Set percentages divisions accordign to the number of warehouses
        let currentPercetagesDivisions = [];
        for (const singleWarehouse of this.selectedWarehouses) {
          currentPercetagesDivisions.push({
            ALMACEN: singleWarehouse,
            PORCENTAGE: round(100 / this.selectedWarehouses.length, 2),
          });
        }

        this.currentIndex += 1;
        this.newIncome.items.push({
          CONCEP_IE: "",
          RENGLON: this.currentIndex,
          PORCEN_IVA: 16,
          PORCEN_IEPS: 0,
          PRECIO_U: 0,
          IVA_GTO: 0,
          IEPS_GTO: 0,
          COST_GTO: 0,
          DESC_GTO: 0,
          ES_ACTIVOF: "N",
          DESCRIBEAR: "",
          SUB_TOT: 0,
          TOTAL: 0,
          CANTIDAD: 1,
          EXENTO: 0,
          ES_EXENTO: "N",
          percentageDivision: currentPercetagesDivisions,
        });
        this.updateTotal();
      }
    },
    async saveIncome() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newIncome,
          this.newIncomeErrors
        );
        this.checkIncomenformation();
        if (
          checkValidationErrors(this.newIncomeErrors) &&
          this.newIncome.items.length > 0 &&
          this.selectedWarehouses.length > 0
        ) {
          this.updateTotal();
          if (!this.checkValidPercentages()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la cuenta por cobrar, reivse que los porcentajes de los almacenes sumen 100% y sean validos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          const response = await this.divideIncomesAccounts();

          if (response.success) {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la cuenta por cobrar",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la cuenta por cobrar en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la cuenta por cobrar en la base de datos revise los campos requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la cuenta por cobrar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async divideIncomesAccounts() {
      try {
        // Create income per selected warehouse
        for (const singleWarehouse of this.selectedWarehouses) {
          let currentIMPORTE_E = 0;
          let currentIVA_GTO = 0;
          let currentIEPS_GTO = 0;
          let curentIMPIMPTO1 = 0;
          let currentTOTAL_GTO = 0;

          let currentItems = [];

          for (const singleItem of this.newIncome.items) {
            let singleItemPercentage = 0;
            for (const singlePercentage of singleItem.percentageDivision) {
              if (singlePercentage.ALMACEN._id === singleWarehouse._id) {
                singleItemPercentage = singlePercentage.PORCENTAGE;
              }
            }

            let currentItemPRECIO_U = toFixedNoRound(
              basicRuleOfThree(100, singleItem.PRECIO_U, singleItemPercentage),
              3
            );
            let currentItemIVA_GTO = toFixedNoRound(
              basicRuleOfThree(100, singleItem.IVA_GTO, singleItemPercentage),
              3
            );
            let currentItemIEPS_GTO = toFixedNoRound(
              basicRuleOfThree(100, singleItem.IEPS_GTO, singleItemPercentage),
              3
            );
            let currentItemCOST_GTO = toFixedNoRound(
              basicRuleOfThree(100, singleItem.COST_GTO, singleItemPercentage),
              3
            );
            let currentItemDESC_GTO = toFixedNoRound(
              basicRuleOfThree(100, singleItem.DESC_GTO, singleItemPercentage),
              3
            );
            let currentItemSUB_TOT = toFixedNoRound(
              basicRuleOfThree(100, singleItem.SUB_TOT, singleItemPercentage),
              3
            );
            let currentItemTOTAL = toFixedNoRound(
              basicRuleOfThree(100, singleItem.TOTAL, singleItemPercentage),
              3
            );

            let currentItem = {
              ...singleItem,
              PRECIO_U: currentItemPRECIO_U,
              IVA_GTO: currentItemIVA_GTO,
              IEPS_GTO: currentItemIEPS_GTO,
              COST_GTO: currentItemCOST_GTO,
              DESC_GTO: currentItemDESC_GTO,
              SUB_TOT: currentItemSUB_TOT,
              TOTAL: currentItemTOTAL,
            };

            currentIMPORTE_E += currentItemSUB_TOT;
            currentIVA_GTO += currentItemIVA_GTO;
            currentIEPS_GTO += currentItemIEPS_GTO;
            curentIMPIMPTO1 += currentItemIVA_GTO;
            curentIMPIMPTO1 += currentItemIEPS_GTO;
            currentTOTAL_GTO += currentItemTOTAL;

            currentItems.push(currentItem);
          }

          const currentWarehouseIncome = {
            ...this.newIncome,
            CLAVE_ALM: singleWarehouse._id,
            IMPORTE_E: currentIMPORTE_E,
            IVA_GTO: currentIVA_GTO,
            IEPS_GTO: currentIEPS_GTO,
            IMPIMPTO1: curentIMPIMPTO1,
            TOTAL_GTO: currentTOTAL_GTO,
            items: currentItems,
          };

          await this.$store.dispatch("ADDINCOME", currentWarehouseIncome);
        }

        return { success: true };
      } catch (error) {
        return { success: false };
      }
    },
    updateTotal() {
      this.newIncome.IMPORTE_E = 0;
      this.newIncome.IVA_GTO = 0;
      this.newIncome.IEPS_GTO = 0;
      this.newIncome.IMPIMPTO1 = 0;
      this.newIncome.TOTAL_GTO = 0;

      for (const [
        singleIncomeIndex,
        singleIncome,
      ] of this.newIncome.items.entries()) {
        let ivaTotal =
          Number(singleIncome.PRECIO_U) *
          (Number(singleIncome.PORCEN_IVA) / 100);
        let iepsTotal =
          Number(singleIncome.PRECIO_U) *
          (Number(singleIncome.PORCEN_IEPS) / 100);
        let total =
          Number(singleIncome.PRECIO_U) + Number(ivaTotal) + Number(iepsTotal);

        // Set item values
        this.newIncome.items[singleIncomeIndex].SUB_TOT = Number(
          singleIncome.PRECIO_U
        );
        this.newIncome.items[singleIncomeIndex].IVA_GTO = ivaTotal;
        this.newIncome.items[singleIncomeIndex].IEPS_GTO = iepsTotal;
        this.newIncome.items[singleIncomeIndex].TOTAL = total;

        // Set income values
        this.newIncome.IMPORTE_E +=
          this.newIncome.items[singleIncomeIndex].SUB_TOT;
        this.newIncome.IVA_GTO +=
          this.newIncome.items[singleIncomeIndex].IVA_GTO;
        this.newIncome.IEPS_GTO +=
          this.newIncome.items[singleIncomeIndex].IEPS_GTO;
        this.newIncome.IMPIMPTO1 +=
          this.newIncome.items[singleIncomeIndex].IVA_GTO +
          this.newIncome.items[singleIncomeIndex].IEPS_GTO;
        this.newIncome.TOTAL_GTO +=
          this.newIncome.items[singleIncomeIndex].TOTAL;
      }
    },
    checkIncomenformation() {
      let validatedItems = [];
      for (let singleItem of this.newIncome.items) {
        if (!singleItem.PORCEN_IVA) {
          singleItem.PORCEN_IVA = 0;
        }
        if (!singleItem.PORCEN_IEPS) {
          singleItem.PORCEN_IEPS = 0;
        }
        if (!singleItem.CONCEP_IE) {
          break;
        }
        if (!singleItem.PRECIO_U) {
          break;
        }
        validatedItems.push(singleItem);
      }
      this.newIncome.items = validatedItems;
    },
    addWarehouse(warehouse) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newIncome.items.entries()) {
        this.newIncome.items[singleItemIndex].percentageDivision.push({
          ALMACEN: warehouse,
          PORCENTAGE: round(100 / this.selectedWarehouses.length, 2),
        });
      }
    },
    removeWarehouse(warehouse) {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newIncome.items.entries()) {
        for (const [
          singleDivisionIndex,
          singleDivision,
        ] of singleItem.percentageDivision.entries()) {
          if (singleDivision.ALMACEN._id === warehouse._id) {
            this.newIncome.items[singleItemIndex].percentageDivision.splice(
              singleDivisionIndex,
              1
            );
          }
        }
      }
    },
    getRandomString() {
      return createRandomString(6);
    },
    checkValidPercentages() {
      for (const singleItem of this.newIncome.items) {
        if (singleItem.CONCEP_IE && singleItem.PRECIO_U) {
          let divisionPercentageTotal = 0;
          for (const singleDivision of singleItem.percentageDivision) {
            divisionPercentageTotal += Number(singleDivision.PORCENTAGE);
          }
          if (divisionPercentageTotal > 100 || divisionPercentageTotal < 98) {
            return false;
          }
        }
      }
      return true;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        return singleWarehouse.CLAVE_ALMACEN !== "Transferencias";
      });
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const singleClientWithProps = { ...singleClient };
        return singleClientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    lastIncomeNumber() {
      return this.$store.getters.LASTINCOMENUMBER;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
