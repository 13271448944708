<template>
  <div class="container add-global-modal-main-container">
    <h3 class="form-division">Total pagado</h3>

    <b-progress
      :rounded="false"
      type="is-success"
      size="is-medium"
      :value="TOTAL_PAGADO"
      :max="income.TOTAL_GTO"
      show-value
      format="raw"
      :precision="2"
      :keep-trailing-zeroes="true"
      locale="es-MX"
    >
      {{ TOTAL_PAGADO | money("MXN", 2) }}
    </b-progress>

    <h3 class="form-division">Pagos realizados</h3>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de pagos: <b>{{ currentPaymentsDetails.length }}</b>
      </div>
      <b-table
        scrollable
        pagination-position="both"
        :data="currentPaymentsDetails"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="paymentDetails"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        class="income-payments"
        default-sort="NUM_FOLIO"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de pago`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        checkbox-position="left"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles de los pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio de pago" field="NUM_FOLIO" sortable>
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column label="Estatus" field="STATUS_FOR" sortable>
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus de facturación"
            field="STATUS_FAC"
            sortable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC)">{{
              "Sin información"
            }}</span>

            <span v-if="props.row.STATUS_FAC === 'SF'">Sin Timbrar</span>
            <span v-if="props.row.STATUS_FAC === 'PF'"
              >Parcialmente timbrada</span
            >
            <span v-if="props.row.STATUS_FAC === 'PP'"
              >Pendiente de timbrar</span
            >
            <span v-if="props.row.STATUS_FAC === 'TI'">Timbrada</span>
            <span v-if="props.row.STATUS_FAC === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column label="Usuario" field="EMAIL_USUARIO" sortable>
            <span v-if="!checkIfValid(props.row.EMAIL_USUARIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.EMAIL_USUARIO }}</span>
          </b-table-column>

          <b-table-column label="Tipo" field="formattedType" sortable>
            <span v-if="!checkIfValid(props.row.formattedType)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formattedType }}</span>
          </b-table-column>

          <b-table-column
            label="Forma de pago"
            field="FORMA_P.DESCRIBE_P"
            sortable
          >
            <span v-if="!checkIfValid(props.row.FORMA_P.DESCRIBE_P)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FORMA_P.DESCRIBE_P }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia de movimineto"
            field="MOVIMIENTO_FOR"
            sortable
          >
            <span v-if="!checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              props.row.MOVIMIENTO_FOR
            }}</span>
          </b-table-column>

          <b-table-column label="Referencia" field="REFERENCIA" sortable>
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column label="Cantidad del pago" field="PAGO_PARC" sortable>
            <span v-if="!checkIfValid(props.row.PAGO_PARC)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.PAGO_PARC | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column label="Fecha de pago" field="formatedDate" sortable>
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showPayment(props.row)"
                    v-if="
                      ((income.CLAVE_VENTA &&
                        income.CLAVE_VENTA.STATUS_FAC === 'TI' &&
                        income.CLAVE_VENTA.METODO_P !== 'P' &&
                        !income.CLAVE_VENTA.useAdvancedPayment) ||
                        (income.CLAVE_VENTA &&
                          income.CLAVE_VENTA.STATUS_FAC === 'PF' &&
                          income.CLAVE_VENTA.METODO_P !== 'P' &&
                          !income.CLAVE_VENTA.useAdvancedPayment) ||
                        (income.CLAVE_VENTA_SERVICIO &&
                          income.CLAVE_VENTA_SERVICIO.STATUS_FAC === 'TI' &&
                          income.CLAVE_VENTA_SERVICIO.METODO_P !== 'P')) &&
                      props.row.STATUS_FAC === 'SF' &&
                      props.row.STATUS !== 'CA' &&
                      type === 'Venta'
                    "
                    >Ver o timbrar pago</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showPayment(props.row)"
                    v-if="
                      props.row.STATUS === 'CA' ||
                      (income.CLAVE_VENTA &&
                        income.CLAVE_VENTA.METODO_P === 'P') ||
                      (income.CLAVE_VENTA_SERVICIO &&
                        income.CLAVE_VENTA_SERVICIO.METODO_P === 'P') ||
                      props.row.STATUS_FAC === 'TI' ||
                      (income.CLAVE_VENTA &&
                        income.CLAVE_VENTA.useAdvancedPayment) ||
                      type === 'Ingreso'
                    "
                    >Ver pago</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="
                      (props.row.STATUS === 'AC' || props.row.STATUS === 'A') &&
                      props.row.STATUS_FAC !== 'TI'
                    "
                    @click="
                      startCancelEvent(
                        'danger',
                        function () {
                          cancelPaymentIncome(props.row);
                        },
                        'cancelar el pago'
                      )
                    "
                    >Cancelar pago</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="
                      (props.row.STATUS === 'AC' || props.row.STATUS === 'A') &&
                      props.row.STATUS_FAC === 'TI'
                    "
                    @click="cancelPayment(props.row)"
                    >Cancelar pago</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showAcuseXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'CA'"
                    >Ver acuse de cancelación</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showPaymentXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="downloadPaymentPDF(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Descargar PDF</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openSendCFDI(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Enviar CFDI y XML</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AddNewPayment from "./AddNewPayment";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import PaymentCanellationModal from "../../Accounting/components/PaymentCanellationModal";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import { EventBus } from "@/event-bus";

export default {
  name: "IncomeDetailsPaymentsModal",
  props: ["paymentsDetails", "income", "type", "componentKey"],
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  mounted() {
    moment.locale("es");
    this.income.TOTAL_GTO = Number(this.income.TOTAL_GTO);
  },
  methods: {
    showPayment(paymentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewPayment,
        props: {
          noInvoice:
            this.income.CLAVE_VENTA &&
            this.income.CLAVE_VENTA.useAdvancedPayment
              ? true
              : false,
          income: this.income,
          objectInformation: paymentInformation,
          type: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloadPaymentPDF(paymentId) {
      try {
        let response = await this.$store.dispatch("GETPAYMENTPDF", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el pdf`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showPaymentXML(paymentId) {
      try {
        let response = await this.$store.dispatch("GETPAYMENTXML", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelPayment(paymentInformation) {
      if (
        paymentInformation.CLAVE_FACTURA &&
        paymentInformation.CLAVE_FACTURA.length > 0
      ) {
        this.$buefy.dialog.confirm({
          title: "¿Desea continuar?",
          message: `El ingreso es de tipo PPD, si se utilizó este pago con una o más cuentas diferentes es necesario cancelar manualmente el pago en las otras cuentas. ¿Desea continuar?`,
          confirmText: "Continuar",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: true,
          onConfirm: () => {
            this.$buefy.modal.open({
              canCancel: ["x"],
              parent: this,
              component: PaymentCanellationModal,
              props: {
                saleId: this.income.CLAVE_VENTA
                  ? this.income.CLAVE_VENTA._id
                  : this.income.CLAVE_VENTA_SERVICIO._id,
                paymentId: paymentInformation._id,
                incomeId: this.income._id,
              },
              hasModalCard: false,
              customClass: "primary-modal-class",
              trapFocus: true,
              fullScreen: false,
              destroyOnHide: true,
            });
          },
        });
      }
    },
    async showAcuseXML(paymentId) {
      try {
        let response = await this.$store.dispatch("GETACUSEPAYMENTXML", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse del pago`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse del pago`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(paymentId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          paymentId: paymentId,
          action: "SENDCFDIPAYMENT",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelPaymentIncome(payment) {
      try {
        let response = await this.$store.dispatch("CANCELPAYMENTINCOME", {
          incomeId: this.income._id,
          paymentId: payment._id,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente el pago",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          EventBus.$emit("closeIncomeModal");
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar el pago en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar el pago en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {
    currentPaymentsDetails() {
      return this.paymentsDetails.map((singlePaymentDetails) => {
        const singlePaymentDetailsWithProps = { ...singlePaymentDetails };
        singlePaymentDetailsWithProps.formatedDate = moment(
          singlePaymentDetailsWithProps.FECHA_PAGO
        ).format("DD-MM-YYYY HH:mm");

        singlePaymentDetailsWithProps.MOVIMIENTO_FOR = "";
        if (singlePaymentDetailsWithProps.CLAVE_MOVIMIENTO) {
          singlePaymentDetailsWithProps.MOVIMIENTO_FOR =
            singlePaymentDetailsWithProps.CLAVE_MOVIMIENTO.REFERENCIA;
        }

        singlePaymentDetailsWithProps.formattedType =
          singlePaymentDetailsWithProps.ES_ANTICIPO === "S"
            ? "Anticipo"
            : "Pago";

        if (singlePaymentDetailsWithProps.STATUS !== "CA") {
          this.TOTAL_PAGADO += singlePaymentDetailsWithProps.PAGO_PARC;
          singlePaymentDetailsWithProps.STATUS_FOR = "Activo";
        }

        if (singlePaymentDetailsWithProps.STATUS === "CA") {
          singlePaymentDetailsWithProps.STATUS_FOR = "Cancelado";
        }
        return singlePaymentDetailsWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
