<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Detalles del ingreso</h1>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item label="Información del ingreso" icon="chart-areaspline">
            <IncomeDetailsModal
              :income="income"
              :type="type"
            ></IncomeDetailsModal>
          </b-tab-item>
          <b-tab-item label="Información de pagos" icon="file-chart-outline">
            <IncomeDetailsPaymentsModal
              :paymentsDetails="income.PAGOS"
              :income="income"
              :type="type"
            ></IncomeDetailsPaymentsModal>
          </b-tab-item>
        </b-tabs>

        <div class="add-global-controls">
          <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IncomeDetailsModal from "./IncomeDetailsModal";
import IncomeDetailsPaymentsModal from "./IncomeDetailsPaymentsModal";
import { EventBus } from "../../../event-bus";

export default {
  name: "IncomeDetailsTabsModal.vue",
  props: ["income", "type"],
  components: {
    IncomeDetailsModal,
    IncomeDetailsPaymentsModal,
  },
  data() {
    return {};
  },
  mounted() {
    EventBus.$on("closeIncomeModal", () => {
      this.closeModal();
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
