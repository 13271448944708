<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Editar información de cuenta por cobrar
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field
      grouped
      class="special-margin"
      :type="{ 'is-danger': incomeInformationRequiredErrors.NO_DOCUM }"
      :message="{
        'El número de documento no es válido':
          incomeInformationRequiredErrors.NO_DOCUM,
      }"
    >
      <b-field
        label="Número de documento (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Venta número 50 del cliente 2"
          expanded
          v-model="incomeInformation.NO_DOCUM"
          icon="numeric-9-circle-outline"
          required
        ></b-input>
      </b-field>

      <b-field label="Descripción (Opcional)" expanded label-position="inside">
        <b-input
          placeholder="Ejemplo: Venta número 50 del cliente 2"
          expanded
          v-model="incomeInformation.OBSERVA"
          icon="clipboard"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" expanded>
      <b-field
        label-position="inside"
        label="Fecha (Requerido)"
        :type="{ 'is-danger': incomeInformationRequiredErrors.FECHA }"
        :message="{
          'La fecha no es valida': incomeInformationRequiredErrors.FECHA,
        }"
        expanded
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha"
          icon="calendar-today"
          required
          v-model="incomeInformation.FECHA"
          trap-focus
        >
        </b-datepicker>
      </b-field>

      <b-field
        label-position="inside"
        label="Fecha de documento (Requerido)"
        :type="{ 'is-danger': incomeInformationRequiredErrors.FECHA_DOC }"
        :message="{
          'La fecha de documento no es valida':
            incomeInformationRequiredErrors.FECHA_DOC,
        }"
        expanded
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha"
          icon="calendar-today"
          required
          v-model="incomeInformation.FECHA_DOC"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <b-field grouped class="special-margin" expanded>
      <b-field
        label-position="inside"
        label="Fecha de vencimiento (Requerido)"
        :type="{ 'is-danger': incomeInformationRequiredErrors.FECHA_VEN }"
        :message="{
          'La fecha de vencimineto no es valida':
            incomeInformationRequiredErrors.FECHA_VEN,
        }"
        expanded
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha"
          icon="calendar-today"
          required
          v-model="incomeInformation.FECHA_VEN"
          trap-focus
        >
        </b-datepicker>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="editAccount()"
        >Editar cuenta por cobrar</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "EditClientAccountModal",
  props: ["income"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      incomeInformation: {
        NO_DOCUM: "",
        OBSERVA: "",
        FECHA_VEN: new Date(),
        FECHA: new Date(),
        FECHA_DOC: new Date(),
      },
      incomeInformationRequiredErrors: {
        NO_DOCUM: false,
        FECHA_VEN: false,
        FECHA: false,
        FECHA_DOC: false,
      },
      moment: moment,
    };
  },
  mounted() {
    if (this.income) {
      fillObjectForm(this.income, this.incomeInformation);
      if (this.income.FECHA) {
        this.incomeInformation.FECHA = new Date(this.income.FECHA);
      }

      if (this.income.FECHA_DOC) {
        this.incomeInformation.FECHA_DOC = new Date(this.income.FECHA_DOC);
      }

      if (this.income.FECHA_VEN) {
        this.incomeInformation.FECHA_VEN = new Date(this.income.FECHA_VEN);
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc Edits a client account in the database
     */
    async editAccount() {
      try {
        this.movementRequiredErrors = validateFields(
          this.incomeInformation,
          this.incomeInformationRequiredErrors
        );

        if (checkValidationErrors(this.incomeInformationRequiredErrors)) {
          let response = await this.$store.dispatch(
            "EDITINCOMEBASICINFORMATION",
            {
              incomeId: this.income._id,
              newIncomeInformation: this.incomeInformation,
            }
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se editó exitosamente la cuenta por cobrar",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al editar la cuenta por cobrar en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al editar la cuenta por cobrar en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al editar la cuenta por cobrar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
