var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v("Agregar pago usando anticipo")]),(!_vm.objectInformation && _vm.accountType === 'CC' && _vm.type === 'Venta')?_c('b-checkbox',{staticClass:"special-margin",model:{value:(_vm.newPayment.requireCDFI),callback:function ($$v) {_vm.$set(_vm.newPayment, "requireCDFI", $$v)},expression:"newPayment.requireCDFI"}},[_vm._v(" Timbrar pago ")]):_vm._e(),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Restante a pagar (Requerido)","expanded":"","label-position":"inside"}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 16","icon":"cash-100","required":true,"disabled":true},model:{value:(_vm.totalQuantity),callback:function ($$v) {_vm.totalQuantity=$$v},expression:"totalQuantity"}})],1)],1),_c('h3',{staticClass:"form-division"},[_vm._v("Información del anticipo")]),_c('b-field',{staticClass:"special-margin required-input",attrs:{"grouped":""}},[_c('b-field',{staticClass:"required-input",attrs:{"label":"Anticipo (Requerido)","expanded":"","label-position":"inside","required":""}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: 100","expanded":"","icon":"credit-card-outline","type":{ 'is-danger': _vm.newPaymentErrors.CLAVE_ANTICIPO },"message":{
          'El anticipo no es valido': _vm.newPaymentErrors.CLAVE_ANTICIPO,
        }},nativeOn:{"change":function($event){return _vm.setAdvancedPaymentBalance()}},model:{value:(_vm.newPayment.CLAVE_ANTICIPO),callback:function ($$v) {_vm.$set(_vm.newPayment, "CLAVE_ANTICIPO", $$v)},expression:"newPayment.CLAVE_ANTICIPO"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Sin seleccionar")]),_vm._l((_vm.advancedPayment),function(singleAdvancesPayment){return _c('option',{key:singleAdvancesPayment._id,domProps:{"value":singleAdvancesPayment._id}},[_vm._v(" "+_vm._s(singleAdvancesPayment.NUM_FOLIO)+" ")])})],2)],1)],1),_c('b-field',{staticClass:"special-margin required-input",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Saldo restante en anticipo (Requerido)","expanded":"","label-position":"inside"}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 16","icon":"cash-100","required":true,"disabled":true},model:{value:(_vm.selectedAdvancedPaymentBalance),callback:function ($$v) {_vm.selectedAdvancedPaymentBalance=$$v},expression:"selectedAdvancedPaymentBalance"}})],1)],1),_c('h3',{staticClass:"form-division"},[_vm._v("Información general")]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Monto (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newPaymentErrors.PAGO_PARC },"message":{
        'El monto del pago no es valido': _vm.newPaymentErrors.PAGO_PARC,
      }}},[_c('MoneyInput',{attrs:{"currency":"MXN","digits":5,"placeholder":"Ejemplo: 16","icon":"cash-100","required":true,"disabled":false},model:{value:(_vm.newPayment.PAGO_PARC),callback:function ($$v) {_vm.$set(_vm.newPayment, "PAGO_PARC", $$v)},expression:"newPayment.PAGO_PARC"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Fecha de pago (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newPaymentErrors.FECHA_PAGO },"message":{
        'La fecha del pago no es valida': _vm.newPaymentErrors.FECHA_PAGO,
      }}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha de pago","icon":"calendar-today","required":"","trap-focus":""},model:{value:(_vm.newPayment.FECHA_PAGO),callback:function ($$v) {_vm.$set(_vm.newPayment, "FECHA_PAGO", $$v)},expression:"newPayment.FECHA_PAGO"}})],1),_c('b-field',{attrs:{"label":"Referencia (Requerido)","expanded":"","label-position":"inside","type":{ 'is-danger': _vm.newPaymentErrors.REFERENCIA },"message":{
        'La referencia del pago no es valida': _vm.newPaymentErrors.REFERENCIA,
      }}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 12387BH","expanded":"","icon":"pencil-box","required":""},model:{value:(_vm.newPayment.REFERENCIA),callback:function ($$v) {_vm.$set(_vm.newPayment, "REFERENCIA", $$v)},expression:"newPayment.REFERENCIA"}})],1)],1),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Observaciones (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: Pago realizado el día Martes","expanded":"","type":"textarea"},model:{value:(_vm.newPayment.OBSERVACIONES),callback:function ($$v) {_vm.$set(_vm.newPayment, "OBSERVACIONES", $$v)},expression:"newPayment.OBSERVACIONES"}})],1)],1),_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.addNewPayment()}}},[_vm._v("Registrar pago")]),(
      _vm.objectInformation &&
      _vm.objectInformation.STATUS_FAC === 'SF' &&
      _vm.type === 'Venta'
    )?_c('b-button',{attrs:{"type":"is-success"},on:{"click":function($event){return _vm.savePaymentInvoice()}}},[_vm._v("Timbrar pago")]):_vm._e(),_c('b-button',{class:{
      'cancel-button':
        !_vm.objectInformation ||
        (_vm.objectInformation &&
          _vm.objectInformation.STATUS_FAC === 'SF' &&
          _vm.type === 'Venta'),
    },attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }