A/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control" v-if="typeValue === 0">
        <b-tooltip
          v-if="providers.length === 0 || warehouses.length === 0"
          label="Primero debes agregar proveedores y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled
            >Agregar cuenta por pagar</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="addExpenseAccount()"
          v-if="providers.length > 0 && warehouses.length"
          >Agregar cuenta por pagar</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="openAddPaymentMultipleAccount()"
          v-if="clients.length > 0"
          >Agregar mismo pago a multiples cuentas por pagar</b-button
        >
      </div>
      <div class="control" v-if="typeValue === 0">
        <b-button type="is-primary" @click="startImport()"
          >Importar gastos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="removeThresholdMissingAccounts()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.treasury.removeMissingAccountsExpenses ===
              'S' &&
            permissions &&
            permissions.treasury.removeMissingAccountsExpenses === 'S'
          "
          >Eliminar saldos menores</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de cuentas por pagar:
      <b>{{ filteredAccounts.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedBrands"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas por pagars</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="
              props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                ? 'red-cell'
                : ''
            "
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="authorizeExpense(props.row)"
                  v-if="
                    props.row.STATUS === 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury.authorizeExpense ===
                      'S' &&
                    permissions &&
                    permissions.treasury.authorizeExpense === 'S'
                  "
                  >Autorizar cuenta por pagar</b-button
                >
                <b-tooltip
                  v-if="
                    props.row.RESTA <= 0 &&
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA'
                  "
                  label="La cuenta por pagar ha sido pagada en su totalidad"
                  multilined
                >
                  <b-button type="is-primary" disabled
                    >Agregar nuevo pago</b-button
                  >
                </b-tooltip>
                <b-button
                  type="is-primary"
                  @click="addPayment(props.row)"
                  v-if="
                    props.row.RESTA > 0 &&
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA'
                  "
                  >Agregar nuevo pago
                </b-button>
                <b-button
                  type="is-primary"
                  @click="useAdvancedPayment(props.row)"
                  v-if="
                    props.row.RESTA > 0 &&
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury.useAdvancedPayment ===
                      'S' &&
                    permissions &&
                    permissions.treasury.useAdvancedPayment === 'S'
                  "
                  >Agregar nuevo pago usando anticipo
                </b-button>
                <b-button
                  v-if="props.row.STATUS !== 'NA'"
                  type="is-primary"
                  @click="showExpenseDetails(props.row)"
                  >Ver detalles y pagos
                </b-button>
                <b-button
                  v-if="
                    type === 0 &&
                    props.row.STATUS !== 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury.cancelExpense === 'S' &&
                    permissions &&
                    permissions.treasury.cancelExpense === 'S'
                  "
                  type="is-primary"
                  @click="
                    startCancelEvent(
                      'danger',
                      function () {
                        cancelExpense(props.row);
                      },
                      'cancelar la cuenta por pagar'
                    )
                  "
                  >Cancelar cuenta por cobrar
                </b-button>
                <b-button
                  type="is-primary"
                  v-if="
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .addManualMovementExpense === 'S' &&
                    permissions &&
                    permissions.treasury.addManualMovementExpense === 'S'
                  "
                  @click="addManualMovement(props.row)"
                  >Agregar movimientos manuales</b-button
                >
                <b-button
                  type="is-primary"
                  v-if="
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .addManualMovementExpense === 'S' &&
                    permissions &&
                    permissions.treasury.addManualMovementExpense === 'S'
                  "
                  @click="manualMovementList(props.row)"
                  >Ver movimientos manuales</b-button
                >
                <b-button
                  type="is-primary"
                  v-if="
                    props.row.STATUS !== 'CA' &&
                    props.row.STATUS !== 'NA' &&
                    companyPaymentPlanModules &&
                    companyPaymentPlanModules.treasury
                      .editExpenseBasicInformation === 'S' &&
                    permissions &&
                    permissions.treasury.editExpenseBasicInformation === 'S'
                  "
                  @click="editProviderAccount(props.row)"
                  >Editar cuenta por pagar</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import AddNewPayment from "./AddNewPayment";
import ExpenseDetailsTabsModal from "./ExpenseDetailsTabsModal";
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddNewExpenseModal from "./AddNewExpenseModal";
import UseAdvancedPayment from "./UseAdvancedPayment";
import AddManualMovementAccountModal from "./AddManualMovementAccountModal";
import ManualMovementsAccountsList from "./ManualMovementsAccountsList";
import EditProviderAccountModal from "./EditProviderAccountModal";
import RemoveMissingAccounts from "./RemoveMissingAccounts";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";
import AddPaymentMultipleExpenses from "@/modules/Treasury/components/AddPaymentMultipleExpenses.vue";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ProvidersAccounts",
  props: ["type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeValue: this.type,
      perPage: 50,
      checkedBrands: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por pagar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PROV_FOR",
          label: "Proveedor o Acreedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CODIGO_CAUSA",
          label: "Código causa",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURA",
          label: "Factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedInvoiceDate",
          label: "Fecha factura",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateInvoice,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha recepción",
          sortable: true,
          searchable: true,
          display: false,
          customSort: this.sortByDate,
        },
        {
          field: "DESCRIPCION",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "IMPORTE_E",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "DESC_GTO",
          label: "Descuentos",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "COST_GTO",
          label: "Indirectos",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },

        {
          field: "IVA_GTO",
          label: "Total IVA",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL_GTO",
          label: "Total a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByTotal,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "PERI",
          label: "Plazo en días",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "formatedExpirationDate",
          label: "Fecha de vencimiento",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateExpiration,
        },
        {
          field: "DIAS_VEN",
          label: "Días vencida",
          sortable: true,
          searchable: true,
          display: false,
        },
      ],
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
      filterProvider: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    addExpenseAccount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewExpenseModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    addPayment(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewPayment,
        props: {
          expense: expenseInformation,
          type: this.type === 0 ? "Compra" : "Gasto",
          accountType: "CP",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    useAdvancedPayment(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: UseAdvancedPayment,
        props: {
          expense: expenseInformation,
          type: this.type === 0 ? "Compra" : "Gasto",
          accountType: "CP",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showExpenseDetails(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ExpenseDetailsTabsModal,
        props: {
          expense: expenseInformation,
          type: this.type === 0 ? "Compra" : "Gasto",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateExpiration(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_VEN).getTime() || -Infinity) -
          (new Date(a.FECHA_VEN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_VEN).getTime() || -Infinity) -
          (new Date(b.FECHA_VEN).getTime() || -Infinity)
        );
      }
    },
    sortByDateInvoice(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_FAC).getTime() || -Infinity) -
          (new Date(a.FECHA_FAC).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_FAC).getTime() || -Infinity) -
          (new Date(b.FECHA_FAC).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    sortByTotal(a, b, isAsc) {
      if (isAsc) {
        return Number(a.TOTAL_GTO) - Number(b.TOTAL_GTO);
      } else {
        return Number(b.TOTAL_GTO) - Number(a.TOTAL_GTO);
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelExpense(expense) {
      try {
        let response = await this.$store.dispatch("CANCELEXPENSE", {
          expenseId: expense._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente la cuenta por pagar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar la cuenta por pagar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar cuenta por pagar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async authorizeExpense(expense) {
      try {
        let response = await this.$store.dispatch("AUTHORIZEEXPENSE", {
          expenseId: expense._id,
        });

        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se autorizó exitosamente la cuenta por pagar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al autorizar la cuenta por pagar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al autorizar cuenta por pagar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "expenses",
          nameImport: "gastos",
          afterAction: "GETEXPENSES",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETEXPENSES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    addManualMovement(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddManualMovementAccountModal,
        props: {
          type: 0,
          expenseId: expenseInformation._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    manualMovementList(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ManualMovementsAccountsList,
        props: {
          movementsList: expenseInformation.MOVIMIENTOS_MANUALES
            ? expenseInformation.MOVIMIENTOS_MANUALES
            : [],
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    editProviderAccount(expenseInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditProviderAccountModal,
        props: {
          expense: expenseInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    removeThresholdMissingAccounts() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: RemoveMissingAccounts,
        props: {
          type: 0,
          accountType: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddPaymentMultipleAccount() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPaymentMultipleExpenses,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providersAccounts() {
      return this.$store.getters.EXPENSES.map((singleExpense) => {
        let singleExpenseProps = { ...singleExpense };

        singleExpenseProps.PROV_FOR = singleExpense.CLAVE_PRV
          ? singleExpense.CLAVE_PRV.NOMBRE_PRV
          : "";

        singleExpenseProps.IVA_GTO = String(singleExpenseProps.IVA_GTO);
        singleExpenseProps.TOTAL_GTO = String(singleExpenseProps.TOTAL_GTO);
        singleExpenseProps.RESTA = String(singleExpenseProps.RESTA);

        if (singleExpenseProps.STATUS === "NA")
          singleExpenseProps.STATUS_FORMATED = "Pendiente autorización";
        if (singleExpenseProps.STATUS === "AC")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PE")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PA")
          singleExpenseProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleExpenseProps.STATUS === "CA") {
          singleExpenseProps.STATUS_FORMATED = "Cancelada";
          singleExpenseProps.RESTA = 0;
        }

        if (singleExpenseProps.STATUS === "VE")
          singleExpenseProps.STATUS_FORMATED = "Vencida";

        singleExpenseProps.ALMACEN_FOR = "";
        if (singleExpenseProps.CLAVE_ALMACEN) {
          singleExpenseProps.ALMACEN_FOR =
            singleExpenseProps.CLAVE_ALMACEN.CLAVE_ALMACEN;
        }

        singleExpenseProps.formatedInvoiceDate = moment(
          singleExpenseProps.FECHA_FAC
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedCreationDate = moment(
          singleExpenseProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedExpirationDate = moment(
          singleExpenseProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");
        return singleExpenseProps;
      }).filter((singleExpense) => {
        return this.type === 0
          ? singleExpense.TIPO_EGR === "E"
          : singleExpense.TIPO_EGR === "C" && singleExpense.STATUS !== "NA";
      });
    },
    filteredAccounts() {
      if (this.filterProvider === "") {
        return this.providersAccounts.map((singleAccount) => {
          return { ...singleAccount };
        });
      }
      return this.providersAccounts.filter((singleAccount) => {
        if (singleAccount.CLAVE_PRV._id === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
