<template>
  <div class="container add-global-modal-main-container">
    <h3 class="form-division">Total pagado</h3>

    <b-progress
      :rounded="false"
      type="is-success"
      size="is-medium"
      :value="TOTAL_PAGADO"
      :max="expense.TOTAL_GTO"
      show-value
      format="raw"
      :precision="2"
      :keep-trailing-zeroes="true"
      locale="es-MX"
    >
      {{ TOTAL_PAGADO | money("MXN", 2) }}
    </b-progress>

    <h3 class="form-division">Pagos realizados</h3>

    <div class="invoice-div-bottom-div">
      <div class="table-header providers-list-global table-header-border">
        Cantidad de pagos: <b>{{ currentPaymentsDetails.length }}</b>
      </div>
      <b-table
        scrollable
        pagination-position="both"
        :data="currentPaymentsDetails"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="paymentDetails"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table"
        :selected.sync="selected"
        default-sort="NUM_FOLIO"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de pago`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        checkbox-position="left"
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles de los pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio de pago" field="NUM_FOLIO" sortable>
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column label="Estatus" field="STATUS_FOR" sortable>
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column label="Usuario" field="EMAIL_USUARIO" sortable>
            <span v-if="!checkIfValid(props.row.EMAIL_USUARIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.EMAIL_USUARIO }}</span>
          </b-table-column>

          <b-table-column label="Tipo" field="formattedType" sortable>
            <span v-if="!checkIfValid(props.row.formattedType)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formattedType }}</span>
          </b-table-column>

          <b-table-column
            label="Forma de pago"
            field="FORMA_P.DESCRIBE_P"
            sortable
          >
            <span v-if="!checkIfValid(props.row.FORMA_P.DESCRIBE_P)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FORMA_P.DESCRIBE_P }}</span>
          </b-table-column>

          <b-table-column label="Referencia" field="REFERENCIA" sortable>
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column label="Cantidad del pago" field="PAGO_PARC" sortable>
            <span v-if="!checkIfValid(props.row.PAGO_PARC)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.PAGO_PARC | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha de pago"
            field="formatedDate"
            sortable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button type="is-primary" @click="showPayment(props.row)"
                    >Ver pago</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS === 'AC'"
                    @click="
                      startCancelEvent(
                        'danger',
                        function () {
                          cancelPayment(props.row);
                        },
                        'cancelar el pago'
                      )
                    "
                    >Cancelar pago</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddNewPayment from "./AddNewPayment";
import moment from "moment";
import { EventBus } from "../../../event-bus";
import { openConfirmationModalDanger } from "../../../utils/component-fns";

export default {
  name: "ExpenseDetailsPaymentsModal.vue",
  props: ["paymentsDetails", "expense", "type"],
  data() {
    return {
      currentPaymentsDetails: this.paymentsDetails,
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      TOTAL_PAGADO: 0,
    };
  },
  methods: {
    showPayment(paymentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewPayment,
        props: {
          expense: this.expense,
          objectInformation: paymentInformation,
          type: this.type,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(a.FECHA_PAGO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(b.FECHA_PAGO).getTime() || -Infinity)
        );
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelPayment(payment) {
      try {
        let response = await this.$store.dispatch("CANCELPAYMENTEXPENSE", {
          expenseId: this.expense._id,
          paymentId: payment._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se canceló exitosamente el pago",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          EventBus.$emit("closeExpenseModal");
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al cancelar el pago en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cancelar el pago en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  mounted() {
    moment.locale("es");
    this.currentPaymentsDetails = this.currentPaymentsDetails.map(
      (singlePaymentDetails) => {
        const singlePaymentDetailsWithProps = { ...singlePaymentDetails };
        singlePaymentDetailsWithProps.formatedDate = moment(
          singlePaymentDetailsWithProps.FECHA_PAGO
        ).format("DD-MM-YYYY HH:mm");
        singlePaymentDetailsWithProps.formattedType =
          singlePaymentDetailsWithProps.ES_ANTICIPO === "S"
            ? "Anticipo"
            : "Pago";
        if (singlePaymentDetailsWithProps.STATUS === "AC") {
          this.TOTAL_PAGADO += singlePaymentDetailsWithProps.PAGO_PARC;
          singlePaymentDetailsWithProps.STATUS_FOR = "Activo";
        }
        if (singlePaymentDetailsWithProps.STATUS === "CA") {
          singlePaymentDetailsWithProps.STATUS_FOR = "Cancelado";
        }
        return singlePaymentDetailsWithProps;
      }
    );
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
