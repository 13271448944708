<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Cuentas </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Resumen" icon="cash-multiple">
        <GeneralResumee></GeneralResumee>
      </b-tab-item>
      <b-tab-item label="Cuentas por pagar acreedores" icon="currency-usd-off">
        <ProvidersAccounts
          :type="0"
          :visible="
            companyPaymentPlanModules.treasury.getGeneralExpense === 'S'
          "
        ></ProvidersAccounts>
      </b-tab-item>
      <b-tab-item label="Cuentas por pagar compras" icon="currency-usd-off">
        <ProvidersAccounts
          :type="1"
          :visible="
            companyPaymentPlanModules.treasury.getProvidersExpense === 'S'
          "
        ></ProvidersAccounts>
      </b-tab-item>
      <b-tab-item label="Cuentas por cobrar deudores" icon="currency-usd">
        <ClientsAccounts
          :type="0"
          :visible="companyPaymentPlanModules.treasury.getGeneralIncome === 'S'"
        ></ClientsAccounts>
      </b-tab-item>
      <b-tab-item
        label="Cuentas por cobrar ventas servicios"
        icon="currency-usd"
      >
        <ClientsAccounts
          :type="2"
          :visible="companyPaymentPlanModules.treasury.getClientIncome === 'S'"
        ></ClientsAccounts>
      </b-tab-item>
      <b-tab-item label="Cuentas por cobrar ventas" icon="currency-usd">
        <ClientsAccounts
          :type="1"
          :visible="companyPaymentPlanModules.treasury.getClientIncome === 'S'"
        ></ClientsAccounts>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ProvidersAccounts from "../components/ProvidersAccounts";
import GeneralResumee from "../components/GeneralResumee";
import ClientsAccounts from "../components/ClientsAccounts";

// @ is an alias to /src
export default {
  name: "Accounts",
  components: {
    GeneralResumee,
    ProvidersAccounts,
    ClientsAccounts,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "accounts");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    allPromises.push(
      this.$store.dispatch("GETEXPENSES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(
      this.$store.dispatch("GETINCOMES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(
      this.$store.dispatch("GETPAYMENTS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    allPromises.push(this.$store.dispatch("GETCAUSECODES"));
    allPromises.push(this.$store.dispatch("GETLASTINCOMENUMBER"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
