<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Eliminar saldos menores</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de los saldos menores a eliminar (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': missingAccountsRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad de los saldos menores a eliminar no es valida':
            missingAccountsRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="missingAccounts.CANTIDAD"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              removeCurrentMissingAccounts();
            },
            'remover saldos menores'
          )
        "
        >Remover saldos menores</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>

    <div class="global-list-main-container" v-if="type === 0">
      <b-table
        pagination-position="both"
        :data="filteredExpenses"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Accounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in expensesColumns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template #footer>
          <div class="has-text-right">
            Total en cuentas a eliminar saldos menores:
            {{ getTableTotalValues(0) | money("MXN", 2) }}
          </div>
        </template>
      </b-table>
    </div>

    <div class="global-list-main-container" v-if="type === 1">
      <b-table
        pagination-position="both"
        :data="filteredIncomes"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Accounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in incomesColumns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template #footer>
          <div class="has-text-right">
            Total en cuentas a eliminar saldos menores:
            {{ getTableTotalValues(1) | money("MXN", 2) }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { openConfirmationModalSucces } from "../../../utils/component-fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "RemoveMissingAccounts",
  props: ["type", "accountType"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      incomesColumns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CLIENTE_FOR",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURAS_FOR",
          label: "Factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NO_DOCUM",
          label: "Número de documento",
          sortable: true,
          searchable: true,
          display: true,
        },

        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FORMA_P_FORMATED",
          label: "Forma de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "IMPORTE_E",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DESC_GTO",
          label: "Descuentos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "COST_GTO",
          label: "Indirectos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },

        {
          field: "IVA_GTO",
          label: "Total IVA",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL_GTO",
          label: "Total a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "PERI",
          label: "Plazo en días",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de creación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "formatedExpirationDate",
          label: "Fecha de vencimiento",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateExpiration,
        },
        {
          field: "DIAS_VEN",
          label: "Días vencida",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      expensesColumns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por pagar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PROV_FOR",
          label: "Proveedor o Acreedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURA",
          label: "Factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedInvoiceDate",
          label: "Fecha factura",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateInvoice,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha recepción",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "DESCRIPCION",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "IMPORTE_E",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "DESC_GTO",
          label: "Descuentos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "COST_GTO",
          label: "Indirectos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },

        {
          field: "IVA_GTO",
          label: "Total IVA",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL_GTO",
          label: "Total a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "PERI",
          label: "Plazo en días",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedExpirationDate",
          label: "Fecha de vencimiento",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateExpiration,
        },
        {
          field: "DIAS_VEN",
          label: "Días vencida",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      missingAccounts: {
        CANTIDAD: "",
      },
      missingAccountsRequiredErrors: {
        CANTIDAD: false,
      },
      moment: moment,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new brand in the database, display messages according to the status
     */
    async removeCurrentMissingAccounts() {
      try {
        this.missingAccountsRequiredErrors = validateFields(
          this.missingAccounts,
          this.missingAccountsRequiredErrors
        );

        if (checkValidationErrors(this.missingAccountsRequiredErrors)) {
          if (!this.checkQuantityMaxValue()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al eliminar los saldos menores, el saldo máximo a eliminar es $10.00",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          let response = await this.$store.dispatch(
            this.type === 0
              ? "REMOVEMISSINGACCOUNTSEXPENSES"
              : "REMOVEMISSINGACCOUNTSINCOMES",
            {
              type: this.AccountType,
              missingAccounts: this.missingAccounts,
            }
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se eliminaron los saldos menores exitosamente",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al eliminar los saldos menores",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al eliminar los saldos menores",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al eliminar los saldos menores",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startConfirmationEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    getTableTotalValues(type) {
      let sum = 0;

      if (type === 0) {
        sum = this.filteredExpenses.reduce(
          (acc, o) => acc + parseInt(o.RESTA),
          0
        );
      } else if (type === 1) {
        sum = this.filteredIncomes.reduce(
          (acc, o) => acc + parseInt(o.RESTA),
          0
        );
      }

      return sum;
    },
    checkQuantityMaxValue() {
      return this.missingAccounts.CANTIDAD <= 10;
    },
  },
  computed: {
    filteredExpenses() {
      return this.$store.getters.EXPENSES.map((singleExpense) => {
        let singleExpenseProps = { ...singleExpense };

        singleExpenseProps.IVA_GTO = String(singleExpenseProps.IVA_GTO);
        singleExpenseProps.TOTAL_GTO = String(singleExpenseProps.TOTAL_GTO);
        singleExpenseProps.RESTA = String(singleExpenseProps.RESTA);

        if (singleExpenseProps.STATUS === "NA")
          singleExpenseProps.STATUS_FORMATED = "Pendiente autorización";
        if (singleExpenseProps.STATUS === "AC")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PE")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PA")
          singleExpenseProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleExpenseProps.STATUS === "CA") {
          singleExpenseProps.STATUS_FORMATED = "Cancelada";
          singleExpenseProps.RESTA = 0;
        }
        if (singleExpenseProps.STATUS === "VE")
          singleExpenseProps.STATUS_FORMATED = "Vencida";

        singleExpenseProps.formatedInvoiceDate = moment(
          singleExpenseProps.FECHA_FAC
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedCreationDate = moment(
          singleExpenseProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedExpirationDate = moment(
          singleExpenseProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");
        return singleExpenseProps;
      }).filter((singleExpense) => {
        return (
          this.missingAccounts.CANTIDAD > 0 &&
          singleExpense.RESTA < this.missingAccounts.CANTIDAD &&
          singleExpense.RESTA > -this.missingAccounts.CANTIDAD &&
          singleExpense.RESTA !== 0 &&
          singleExpense.STATUS !== "VE" &&
          singleExpense.STATUS !== "CA" &&
          singleExpense.STATUS !== "PA"
        );
      });
    },
    filteredIncomes() {
      return this.$store.getters.INCOMES.map((singleIncome) => {
        let singleIncomeWithProps = { ...singleIncome };

        singleIncomeWithProps.IVA_GTO = String(singleIncomeWithProps.IVA_GTO);
        singleIncomeWithProps.TOTAL_GTO = String(
          singleIncomeWithProps.TOTAL_GTO
        );
        singleIncomeWithProps.RESTA = String(singleIncomeWithProps.RESTA);

        singleIncomeWithProps.FORMA_P_FORMATED = "";
        if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
          singleIncomeWithProps.FORMA_P_FORMATED =
            singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P;
        }

        singleIncomeWithProps.FACTURAS_FOR = "";
        if (
          singleIncomeWithProps.CLAVE_VENTA &&
          singleIncomeWithProps.CLAVE_VENTA.FACTURAS &&
          singleIncomeWithProps.CLAVE_VENTA.FACTURAS.length > 0
        ) {
          singleIncomeWithProps.FACTURAS_FOR =
            singleIncomeWithProps.CLAVE_VENTA.FACTURAS[0].CLAVE_FACTURA;
        }

        if (singleIncomeWithProps.STATUS === "AC")
          singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleIncomeWithProps.STATUS === "PE")
          singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleIncomeWithProps.STATUS === "PA")
          singleIncomeWithProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleIncomeWithProps.STATUS === "CA") {
          singleIncomeWithProps.STATUS_FORMATED = "Cancelada";
          singleIncomeWithProps.RESTA = 0;
        }
        if (singleIncomeWithProps.STATUS === "VE") {
          singleIncomeWithProps.STATUS_FORMATED = "Vencida";
        }

        singleIncomeWithProps.formatedCreationDate = moment(
          singleIncomeWithProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleIncomeWithProps.formatedExpirationDate = moment(
          singleIncomeWithProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");
        return singleIncomeWithProps;
      }).filter((singleIncome) => {
        return (
          this.missingAccounts.CANTIDAD > 0 &&
          singleIncome.RESTA <= this.missingAccounts.CANTIDAD &&
          singleIncome.RESTA >= -this.missingAccounts.CANTIDAD &&
          singleIncome.RESTA !== 0 &&
          singleIncome.STATUS !== "CA"
        );
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
