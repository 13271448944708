/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Agregar mismo pago a multiples cuentas por cobrar
    </h1>

    <div>
      <b-field grouped class="special-margin invoice-article-div">
        <b-field label="Proveedor (Requerido)" expanded label-position="inside">
          <b-autocomplete
            :disabled="newPayment.CLAVE_PRV !== ''"
            placeholder="proveedor"
            :data="autoCompleteProvider(providerName)"
            v-model="providerName"
            field="NOMBRE_PRV"
            autocomplete
            icon="credit-card-outline"
            open-on-focus
            expanded
            clearable
            @select="
              (option) => {
                newPayment.CLAVE_PRV = option._id;
              }
            "
            check-infinite-scroll
          >
            <template #empty>No se encontraron proveedores</template>
          </b-autocomplete>
        </b-field>
      </b-field>
    </div>

    <div v-if="newPayment.CLAVE_PRV">
      <div>
        <h3 class="form-division">Información de cuenta</h3>
        <b-field grouped class="special-margin">
          <b-field
            label="Cuenta bancaria (Requerido)"
            expanded
            label-position="inside"
            required
            class="required-input"
            :type="{ 'is-danger': newPaymentErrors.CUENTA }"
            :message="{
              'La cuenta bancaria del pago no es valida':
                newPaymentErrors.CUENTA,
            }"
          >
            <b-select
              placeholder="Ejemplo: Cuenta de gastos"
              expanded
              icon="credit-card-outline"
              v-model="newPayment.CUENTA"
              @change.native="setAccountInformation()"
            >
              <option value="" disabled>Sin seleccionar</option>
              <option
                :value="singleAccount._id"
                v-for="singleAccount in bankAccounts"
                v-bind:key="singleAccount._id"
              >
                {{
                  singleAccount.NOMBRE
                    ? singleAccount.NOMBRE
                    : singleAccount.CUENTA
                }}
              </option>
            </b-select>
          </b-field>
        </b-field>

        <b-progress
          v-if="newPayment.CUENTA"
          :rounded="false"
          type="is-success"
          size="is-medium"
          :value="selectedAccountBalance"
          :max="selectedAccountBalance"
          show-value
          format="raw"
          :precision="2"
          :keep-trailing-zeroes="true"
          locale="es-MX"
        >
          {{ selectedAccountBalance | money("MXN", 2) }}
        </b-progress>

        <b-field grouped class="special-margin invoice-article-div">
          <b-field
            :label="
              companyInformation.MOVIMIENTO_OBLIGATORIO
                ? 'Movimiento bancario a usar (Requerido)'
                : 'Movimiento bancario a usar (Opcional)'
            "
            expanded
            label-position="inside"
            :class="{
              'required-input': companyInformation.MOVIMIENTO_OBLIGATORIO,
            }"
            :type="{
              'is-danger':
                newPaymentErrors.CLAVE_MOVIMIENTO &&
                companyInformation.MOVIMIENTO_OBLIGATORIO,
            }"
            :message="{
              'El movimiento bancario del pago no es válido':
                newPaymentErrors.CLAVE_MOVIMIENTO &&
                companyInformation.MOVIMIENTO_OBLIGATORIO,
            }"
          >
            <b-autocomplete
              placeholder="Movimiento bancario"
              :data="autoCompleteMovement(movementName)"
              v-model="movementName"
              field="MOVIMIENTO_NOMBRE_FOR"
              autocomplete
              icon="credit-card-outline"
              open-on-focus
              expanded
              clearable
              @select="
                (option) => {
                  if (option) {
                    newPayment.CLAVE_MOVIMIENTO = option._id;
                    handleMovementSelected();
                  } else {
                    newPayment.CLAVE_MOVIMIENTO = undefined;
                    selectedAccountMovementBalance = 0;
                  }
                }
              "
              check-infinite-scroll
            >
              <template #empty>No se encontraron movimientos</template>
            </b-autocomplete>
          </b-field>
        </b-field>
        <b-field
          label="Disponible a usar en movimiento (Opcional)"
          expanded
          label-position="inside"
          v-if="newPayment.CLAVE_MOVIMIENTO"
          class="special-margin"
        >
          <MoneyInput
            currency="MXN"
            :digits="5"
            placeholder="Ejemplo: 16"
            icon="cash-100"
            v-model="selectedAccountMovementBalance"
            :required="true"
            :disabled="true"
          >
          </MoneyInput>
        </b-field>
      </div>

      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Forma de pago (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newPaymentErrors.FORMA_P }"
          :message="{
            'El tipo del pago no es valido': newPaymentErrors.FORMA_P,
          }"
        >
          <b-select
            placeholder="Ejemplo: Transferencia"
            expanded
            icon="credit-card-plus-outline"
            class="required-input"
            required
            v-model="newPayment.FORMA_P"
          >
            <option value="" disabled>Selecciones una forma de pago</option>
            <option
              :value="singlePaymentType._id"
              v-for="singlePaymentType in paymentTypes"
              v-bind:key="singlePaymentType._id"
            >
              {{ singlePaymentType.DESCRIBE_P }}
            </option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Número de cuenta (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Martillo azul con mango de madera"
            expanded
            icon="card-account-details-outline"
            v-model="newPayment.NO_CUENTA"
          ></b-input>
        </b-field>
        <b-field
          label="CLABE interbancaria (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 000000000000000000"
            expanded
            icon="bank-outline"
            v-model="newPayment.NO_CLABE"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Número de tarjeta (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 0000000000000000"
            expanded
            icon="card-text-outline"
            v-model="newPayment.NO_TARJETA"
          ></b-input>
        </b-field>
        <b-field
          label="Número de cheque (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 12398"
            expanded
            icon="card-bulleted-outline"
            v-model="newPayment.NO_CHEQUE"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Fecha de pago (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newPaymentErrors.FECHA_PAGO }"
          :message="{
            'La fecha del pago no es valida': newPaymentErrors.FECHA_PAGO,
          }"
        >
          <b-datepicker
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha de pago"
            icon="calendar-today"
            required
            v-model="newPayment.FECHA_PAGO"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Referencia (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newPaymentErrors.REFERENCIA }"
          :message="{
            'La referencia del pago no es valida': newPaymentErrors.REFERENCIA,
          }"
        >
          <b-input
            placeholder="Ejemplo: 12387BH"
            expanded
            icon="pencil-box"
            required
            v-model="newPayment.REFERENCIA"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Observaciones (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Pago realizado el día Martes"
            expanded
            v-model="newPayment.OBSERVACIONES"
            type="textarea"
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div v-if="newPayment.CLAVE_PRV">
      <div class="global-lists-search">
        <div class="global-lists-filters-columns">
          <b-checkbox
            v-for="singleColumn in columns"
            v-bind:key="singleColumn.label"
            v-model="singleColumn.display"
          >
            {{ singleColumn.label }}
          </b-checkbox>
        </div>
        <div class="global-lists-filters-container">
          <b-field
            label="Fecha de inicio"
            label-position="inside"
            class="no-margin"
            :type="{ 'is-danger': errors.startDate }"
            :message="{
              'La fecha de inicio no es valida': errors.startDate,
            }"
          >
            <b-datepicker
              class="global-lists-filters-control"
              @input="(value) => changeDateFilterValue(value)"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              :show-week-number="true"
              locale="es-MX"
              placeholder="Fecha de inicio"
              icon="calendar-today"
              v-model="startDate"
              trap-focus
            >
            </b-datepicker>
          </b-field>
          <b-field
            label="Fecha de fin"
            label-position="inside"
            class="no-margin"
            :type="{ 'is-danger': errors.endDate }"
            :message="{
              'La fecha de fin no es valida': errors.endDate,
            }"
          >
            <b-datepicker
              class="global-lists-filters-control"
              @input="(value) => changeDateFilterValue(value)"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              :show-week-number="true"
              locale="es-MX"
              placeholder="Fecha de fin"
              icon="calendar-today"
              v-model="endDate"
              trap-focus
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>

      <div class="table-header">
        Cantidad de cuentas por pagar:
        <b>{{ filteredAccounts.length }}</b>
      </div>
      <div class="global-list-main-container">
        <b-table
          pagination-position="both"
          :data="filteredAccounts"
          :striped="true"
          :bordered="true"
          :hoverable="true"
          ref="Brands"
          :checked-rows.sync="checkedIncomes"
          paginated
          :current-page="currentPage"
          :per-page="perPage"
          :opened-detailed="defaultOpenedDetails"
          sort-icon="arrow-up"
          sort-icon-size="is-small"
          default-sort-direction="desc"
          checkbox-position="left"
          scrollable
          default-sort="NUM_FOLIO"
        >
          <template #empty>
            <div class="has-text-centered">No hay cuentas por pagar</div>
          </template>

          <template slot-scope="props">
            <b-table-column
              v-for="column in columns"
              searchable
              sortable
              v-bind="column"
              :visible="column.display"
              :key="column.field"
              :label="column.label"
              :custom-sort="column.customSort"
              :cell-class="
                props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                  ? 'red-cell'
                  : ''
              "
            >
              <span
                v-if="!checkIfValid(props.row[column.field]) && !column.money"
                >{{ "Sin información" }}</span
              >
              <span v-if="column.money">{{
                props.row[column.field] | money("MXN", 2)
              }}</span>
              <span v-if="!column.money">{{ props.row[column.field] }}</span>
            </b-table-column>

            <b-table-column label="Monto" sortable>
              <b-field grouped class="add-global-modal-name">
                <b-field
                  label="Monto"
                  expanded
                  label-position="inside"
                  class="special-simple-margin"
                >
                  <MoneyInput
                    currency="MXN"
                    :digits="2"
                    placeholder="Ejemplo: 123"
                    :required="false"
                    v-model="props.row.PAGO_PARC"
                    :disabled="false"
                  />
                </b-field>
              </b-field>
            </b-table-column>
          </template>
        </b-table>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="addNewPayment()"
        >Registrar pago</b-button
      >

      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "@/event-bus";
import moment from "moment";
import { checkValidationErrors, validateFields } from "@/utils/fns";
import SelectMovement from "./SelectMovement";

export default {
  name: "AddPaymentMultipleExpenses",
  props: [],
  components: {},
  data() {
    return {
      // Table values
      newPayment: {
        CLAVE_FACTURA_GASTOS: [],
        CUENTA: "",
        CLAVE_PRV: "",
        NO_CUENTA: "",
        NO_CLABE: "",
        NO_TARJETA: "",
        NO_CHEQUE: "",
        FAC_PRO: "",
        NO_DOCUM: "",
        FECHA_PAGO: new Date(),
        TIPO_PAGOP: "A",
        CLAVE_NOT: "",
        REFERENCIA: "",
        FORMA_P: "",
        TIPO_CH: "",
        STATUS: "AC",
        ES_ANTICIPO: "N",
        CLAVE_MOVIMIENTO: undefined,
        OBSERVACIONES: "",
        createMovement: false,
        affectBalance: false,
        requireCDFI: false,
      },
      newPaymentErrors: {
        CUENTA: false,
        FORMA_P: false,
        FECHA_PAGO: false,
        REFERENCIA: false,
      },
      selectedAccountBalance: 0,
      selectedAccountMovements: [],
      selectedAccountMovementBalance: 0,
      totalQuantity: 0,
      clientIncomes: [],
      movementName: "",
      providerName: "",
      perPage: 50,
      checkedIncomes: [],
      defaultOpenedDetails: [],
      currentPage: 1,
      columns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURA",
          label: "Factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "TOTAL_GTO",
          label: "Total a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByTotal,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de creación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "DIAS_VEN",
          label: "Días vencida",
          sortable: true,
          searchable: true,
          display: false,
        },
      ],
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    if (this.companyInformation.MOVIMIENTO_OBLIGATORIO) {
      this.newPaymentErrors.CLAVE_MOVIMIENTO = false;
    }

    EventBus.$on("searchMovementSelected", (selection) => {
      this.movementName = selection.movement.REFERENCIA;
      this.newPayment.CLAVE_MOVIMIENTO = selection.movement._id;
      this.handleMovementSelected();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeParentModal() {
      EventBus.$emit("closeIncomeModal");
      this.closeModal();
    },
    async addNewPayment() {
      try {
        this.articleRequiredErrors = validateFields(
          this.newPayment,
          this.newPaymentErrors
        );

        if (checkValidationErrors(this.newPaymentErrors)) {
          if (this.validateExpenseSelected()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Llene la informació de una o más cuentas por pagar`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          if (!this.expenseQuantityNotValid()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `La suma de los montos de las cuentas por cobrar seleccionadas debe ser igual al restante del movimiento seleccionado`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          if (this.expenseNotValidQuantity()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: `Hubo un error al guardar el pago, la cantidad a registrar es mayor que el restante a pagar de alguna de las cuenta por cobrar seleccionadas`,
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          const paymentToSend = this.formatPayments();

          let response = await this.$store.dispatch(
            "ADDPAYMENTMULTIPLEEXPENSE",
            paymentToSend
          );

          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el pago",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el pago en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el pago en la base de datos revise los campos Requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el pago la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc sets the balance of the current selected bank account
     * @return { number } the balance on the selected bank account
     */
    setAccountInformation() {
      for (const singleBankAccount of this.bankAccounts) {
        if (this.newPayment.CUENTA === singleBankAccount._id) {
          this.selectedAccountBalance =
            singleBankAccount.SALDO_CON_ACTUAL.SALDO_ACTUAL;

          this.selectedAccountMovements = singleBankAccount.MOVIMIENTOS.map(
            (singleMovement) => {
              const movementWithProps = { ...singleMovement };
              movementWithProps.MOVIMIENTO_NOMBRE_FOR = `${
                singleMovement.REFERENCIA
              } ${this.formatMoneyString(singleMovement.IMPORTE)}`;
              return movementWithProps;
            }
          ).filter((singleMovement) => {
            if (
              singleMovement.STATUS === "CA" ||
              singleMovement.MOSTRAR_TESORERIA === "N"
            ) {
              return false;
            }

            if (
              !singleMovement.CLAVE_CLIENTE &&
              !singleMovement.CLAVE_PRV &&
              singleMovement.TIPOAFECTACION === "RE" &&
              singleMovement.RESTA > 1
            ) {
              return true;
            }

            if (
              singleMovement.CLAVE_PRV &&
              singleMovement.CLAVE_PRV._id === this.newPayment.CLAVE_PRV &&
              singleMovement.TIPOAFECTACION === "RE" &&
              singleMovement.RESTA > 1
            ) {
              return true;
            }

            return false;
          });

          this.allSelectedAccountMovements = this.selectedAccountMovements;
        }
      }
    },
    handleMovementSelected() {
      if (this.newPayment.CLAVE_MOVIMIENTO) {
        this.newPayment.affectBalance = false;
        this.newPayment.createMovement = false;

        for (const singleMovement of this.allSelectedAccountMovements) {
          if (singleMovement._id === this.newPayment.CLAVE_MOVIMIENTO) {
            this.selectedAccountMovementBalance = singleMovement.RESTA;
          }
        }
      }
    },
    expenseNotValidQuantity() {
      for (const singleExpense of this.filteredAccounts) {
        if (singleExpense.PAGO_PARC > singleExpense.RESTA) {
          return true;
        }
      }

      return false;
    },
    validateExpenseSelected() {
      if (this.filteredAccounts.length === 0) {
        return true;
      }

      for (const singleExpense of this.filteredAccounts) {
        if (singleExpense.PAGO_PARC) {
          return false;
        }
      }

      return true;
    },
    expenseQuantityNotValid() {
      if (!this.newPayment.CLAVE_MOVIMIENTO) {
        return false;
      }

      let expensesPaidAmount = 0;

      for (const singleExpense of this.filteredAccounts) {
        if (singleExpense.PAGO_PARC) {
          expensesPaidAmount += singleExpense.PAGO_PARC;
        }
      }

      let finalQuantity =
        this.selectedAccountMovementBalance - expensesPaidAmount;

      return true;
      // return finalQuantity >= 0 && finalQuantity <= 1;
    },
    autoCompleteMovement(value) {
      if (!this.selectedAccountMovements) {
        return [];
      }
      return this.selectedAccountMovements.filter((singleMovement) => {
        return (
          singleMovement.IMPORTE &&
          singleMovement.IMPORTE.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    autoCompleteProvider(value) {
      if (!this.providers) {
        return [];
      }

      return this.providers.filter((singleProvider) => {
        return (
          singleProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singleProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
    formatMoneyString(val) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parseFloat(val));
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    sortByTotal(a, b, isAsc) {
      if (isAsc) {
        return Number(a.TOTAL_GTO) - Number(b.TOTAL_GTO);
      } else {
        return Number(b.TOTAL_GTO) - Number(a.TOTAL_GTO);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETEXPENSES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    formatPayments() {
      let paymentInformation = { ...this.newPayment, CLAVE_FACTURA_GASTOS: [] };

      for (const singleExpense of this.filteredAccounts) {
        if (singleExpense.PAGO_PARC > 0) {
          paymentInformation.CLAVE_FACTURA_GASTOS.push({
            FACTURA: singleExpense.FACTURA,
            PAGO_PARC: singleExpense.PAGO_PARC,
            GASTO: singleExpense._id,
          });
        }
      }

      return paymentInformation;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    providersAccounts() {
      return this.$store.getters.EXPENSES.map((singleExpense) => {
        let singleExpenseProps = { ...singleExpense };

        singleExpenseProps.PROV_FOR = singleExpense.CLAVE_PRV
          ? singleExpense.CLAVE_PRV.NOMBRE_PRV
          : "";

        singleExpenseProps.IVA_GTO = String(singleExpenseProps.IVA_GTO);
        singleExpenseProps.TOTAL_GTO = String(singleExpenseProps.TOTAL_GTO);
        singleExpenseProps.RESTA = String(singleExpenseProps.RESTA);

        if (singleExpenseProps.STATUS === "NA")
          singleExpenseProps.STATUS_FORMATED = "Pendiente autorización";
        if (singleExpenseProps.STATUS === "AC")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PE")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PA")
          singleExpenseProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleExpenseProps.STATUS === "CA") {
          singleExpenseProps.STATUS_FORMATED = "Cancelada";
          singleExpenseProps.RESTA = 0;
        }

        if (singleExpenseProps.STATUS === "VE")
          singleExpenseProps.STATUS_FORMATED = "Vencida";

        singleExpenseProps.ALMACEN_FOR = "";
        if (singleExpenseProps.CLAVE_ALMACEN) {
          singleExpenseProps.ALMACEN_FOR =
            singleExpenseProps.CLAVE_ALMACEN.CLAVE_ALMACEN;
        }

        singleExpenseProps.formatedInvoiceDate = moment(
          singleExpenseProps.FECHA_FAC
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedCreationDate = moment(
          singleExpenseProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedExpirationDate = moment(
          singleExpenseProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");
        return singleExpenseProps;
      });
    },
    filteredAccounts() {
      if (this.filterProvider === "") {
        return this.providersAccounts.map((singleAccount) => {
          return { ...singleAccount };
        });
      }

      return this.providersAccounts.filter((singleAccount) => {
        if (singleAccount.CLAVE_PRV._id === this.newPayment.CLAVE_PRV) {
          return true;
        }
        return false;
      });
    },
    bankAccounts() {
      return this.$store.getters.BANKACCOUNTS;
    },
    paymentTypes() {
      return this.$store.getters.PAYMENTTYPES;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
  },
  watch: {
    companyInformation() {
      if (this.companyInformation.MOVIMIENTO_OBLIGATORIO) {
        this.newPaymentErrors.CLAVE_MOVIMIENTO = false;
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
@import "../../Configuration/style/Configuration.css";
</style>
