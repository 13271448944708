<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Detalles del gasto</h1>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed" v-model="openedTab">
          <b-tab-item label="Información del gasto" icon="chart-areaspline">
            <ExpenseDetailsModal
              :expense="expense"
              :type="type"
            ></ExpenseDetailsModal>
          </b-tab-item>
          <b-tab-item label="Información de pagos" icon="file-chart-outline">
            <ExpenseDetailsPaymentsModal
              :paymentsDetails="expense.PAGOS"
              :expense="expense"
              :type="type"
            ></ExpenseDetailsPaymentsModal>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import ExpenseDetailsModal from "./ExpenseDetailsModal";
import ExpenseDetailsPaymentsModal from "./ExpenseDetailsPaymentsModal";
import { EventBus } from "../../../event-bus";

export default {
  name: "ExpenseDetailsTabsModal.vue",
  props: ["expense", "type", "openedTabProp"],
  components: {
    ExpenseDetailsModal,
    ExpenseDetailsPaymentsModal,
  },
  data() {
    return {
      openedTab: 0,
    };
  },
  mounted() {
    if (this.openedTabProp) {
      this.openedTab = this.openedTabProp;
    }

    EventBus.$on("closeExpenseModal", () => {
      this.closeModal();
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
