<template>
  <div class="container add-global-modal-main-container">
    <h3 class="form-division">Total restante a cobrar</h3>
    <b-field
      label-position="inside"
      label="Restante total"
      class="special-margin"
    >
      <MoneyInput
        currency="MXN"
        :digits="5"
        placeholder="Ejemplo: 16"
        :required="false"
        v-model="income.RESTA"
        :disabled="true"
      >
      </MoneyInput>
    </b-field>

    <h3 class="form-division">Porcentaje cobrado</h3>
    <b-progress
      :rounded="false"
      type="is-success"
      size="is-medium"
      :value="currentIncomeDetails.PAGADO_POR"
      :max="100"
      show-value
      format="percent"
      :precision="2"
      :keep-trailing-zeroes="true"
      locale="es-MX"
    >
    </b-progress>

    <h3 class="form-division">Información general</h3>
    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Cliente (Req)"
      >
        <b-select
          expanded
          placeholder="Cliente"
          icon="earth"
          v-model="currentIncomeDetails.CLAVE_CLIENTE._id"
          disabled
        >
          <option value="" disabled>Seleccione un cliente</option>
          <option
            v-for="client in clients"
            :value="client._id"
            :key="client._id"
          >
            {{ client.NOMBRE_EMPRESA }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Folio de ingreso (Req)"
      >
        <b-input
          placeholder="Folio"
          icon="pound-box-outline"
          v-model="currentIncomeDetails.NUM_FOLIO"
          type="text"
          disabled
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Fecha de ingreso (Req)"
      >
        <b-datepicker
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de ingreso"
          icon="calendar-today"
          v-model="currentIncomeDetails.FECHA_FOR"
          disabled
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Almacen (Opc)"
      >
        <b-select
          placeholder="Almacen"
          icon="warehouse"
          v-model="currentIncomeDetails.CLAVE_ALMACEN._id"
          disabled
          expanded
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse._id"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div">
      <div class="invoice-div-bottom-div">
        <h3 class="form-division">Detalles del ingreso</h3>

        <div class="invoice-add-item add-global-modal-name">
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Número de documento (Req)"
          >
            <b-input
              placeholder="Número de documento"
              icon="numeric-9-circle-outline"
              type="text"
              disabled
              v-model="currentIncomeDetails.NO_DOCUM"
            >
            </b-input>
          </b-field>

          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Fecha de documento (Req)"
          >
            <b-datepicker
              :show-week-number="true"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              placeholder="Fecha de documento"
              icon="calendar-today"
              trap-focus
              disabled
              v-model="currentIncomeDetails.FECHA_DOC_FOR"
            >
            </b-datepicker>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Comentarios (Opc)"
          >
            <b-input
              placeholder="Comentarios"
              type="text"
              icon="magnify-plus-outline"
              disabled
              v-model="currentIncomeDetails.OBSERVA"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Plazo en días (Req)"
          >
            <b-input
              placeholder="Plazo"
              icon="numeric-4-circle-outline"
              type="text"
              disabled
              v-model="currentIncomeDetails.PERI"
            >
            </b-input>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Fecha de vencimiento (Req)"
          >
            <b-datepicker
              :show-week-number="true"
              :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
              :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
              :month-names="[
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
              ]"
              placeholder="Fecha de vencimiento"
              icon="calendar-today"
              trap-focus
              disabled
              v-model="currentIncomeDetails.FECHA_VEN_FOR"
            >
            </b-datepicker>
          </b-field>
          <b-field
            class="invoice-add-secondary-item-three special-big-margin"
            label-position="inside"
            label="Días vencida (Req)"
          >
            <b-input
              placeholder="Días vencida"
              icon="numeric-4-circle-outline"
              type="text"
              disabled
              v-model="currentIncomeDetails.DIAS_VEN"
            >
            </b-input>
          </b-field>
        </div>
      </div>

      <div class="table-header providers-list-global table-header-border">
        Cantidad de elementos en ingreso:
        <b>{{ currentIncomeDetails.ING_DET.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="currentIncomeDetails.ING_DET"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="IncomeDetails"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table"
        :selected.sync="selected"
        default-sort="CONCEP_IE"
        scrollable
      >
        <template #empty>
          <div class="has-text-centered">No hay detalles del ingreso</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Concepto" field="CONCEP_IE" sortable>
            <b-input
              placeholder="Ejemplo: Venta a proveedor"
              expanded
              v-model="props.row.CONCEP_IE"
              required
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Descripción" field="DESCRIBEAR" sortable>
            <b-input
              placeholder="Ejemplo: Pago de enero"
              expanded
              v-model="props.row.DESCRIBEAR"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column label="Costo" field="PRECIO_U" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.PRECIO_U"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total de IVA" field="IVA_GTO" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.IVA_GTO"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total de IEPS" field="IEPS_GTO" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.IEPS_GTO"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Total" field="TOTAL" sortable>
            <MoneyInput
              currency="MXN"
              :digits="5"
              placeholder="Ejemplo: 16"
              v-model="props.row.TOTAL"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Pagado" field="TOTAL" sortable>
            <MoneyInput
              currency="MXN"
              :digits="2"
              placeholder="Ejemplo: 16"
              v-model="props.row.PAGADO"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Faltante" field="TOTAL" sortable>
            <MoneyInput
              currency="MXN"
              :digits="2"
              placeholder="Ejemplo: 16"
              v-model="props.row.RESTA"
              :required="false"
              :disabled="true"
            >
            </MoneyInput>
          </b-table-column>

          <b-table-column label="Porcentaje pagado" field="PAGADO_POR" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.PAGADO_POR"
              disabled
            ></b-input>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { basicRuleOfThree } from "../../../utils/fns";

export default {
  name: "IncomeDetailsModal",
  props: ["income"],
  data() {
    return {
      currentIncomeDetails: { ...this.income },
      moment: moment,
      perPage: 25,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
    };
  },
  created() {},
  methods: {},
  mounted() {
    if (
      this.currentIncomeDetails.RESTA !== undefined &&
      this.currentIncomeDetails.RESTA !== null
    ) {
      this.currentIncomeDetails.PAGADO =
        this.currentIncomeDetails.TOTAL_GTO - this.currentIncomeDetails.RESTA;
    } else {
      this.currentIncomeDetails.PAGADO = 0;
      this.currentIncomeDetails.RESTA = this.currentIncomeDetails.TOTAL_GTO;
    }
    this.currentIncomeDetails.PAGADO_POR = Number(
      basicRuleOfThree(
        this.currentIncomeDetails.TOTAL_GTO,
        100,
        this.currentIncomeDetails.PAGADO
      ).toFixed(2)
    );
    this.currentIncomeDetails.PAGADO =
      this.currentIncomeDetails.TOTAL_GTO - this.currentIncomeDetails.RESTA;
    this.currentIncomeDetails.FECHA_DOC_FOR = new Date(
      this.currentIncomeDetails.FECHA_DOC
    );
    this.currentIncomeDetails.FECHA_MOD_FOR = new Date(
      this.currentIncomeDetails.FECHA_MOD
    );
    this.currentIncomeDetails.FECHA_FOR = new Date(
      this.currentIncomeDetails.FECHA
    );
    this.currentIncomeDetails.FECHA_VEN_FOR = new Date(
      this.currentIncomeDetails.FECHA_VEN
    );
    this.currentIncomeDetails.DIAS_VEN = this.currentIncomeDetails.DIAS_VEN
      ? this.currentIncomeDetails.DIAS_VEN
      : 0;
    this.currentIncomeDetails.ING_DET = this.currentIncomeDetails.ING_DET.map(
      (singleIncomeDetail) => {
        const singleIncomeDetailsWithProps = { ...singleIncomeDetail };
        if (
          singleIncomeDetailsWithProps.RESTA !== undefined &&
          singleIncomeDetailsWithProps.RESTA !== null
        ) {
          singleIncomeDetailsWithProps.PAGADO =
            singleIncomeDetailsWithProps.TOTAL -
            singleIncomeDetailsWithProps.RESTA;
        } else {
          singleIncomeDetailsWithProps.PAGADO = 0;
          singleIncomeDetailsWithProps.RESTA =
            singleIncomeDetailsWithProps.TOTAL;
        }
        singleIncomeDetailsWithProps.PAGADO_POR =
          singleIncomeDetailsWithProps.TOTAL !== 0
            ? `${basicRuleOfThree(
                singleIncomeDetailsWithProps.TOTAL,
                100,
                singleIncomeDetailsWithProps.PAGADO
              ).toFixed(2)}%`
            : "100%";
        return singleIncomeDetailsWithProps;
      }
    );
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
